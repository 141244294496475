<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox" v-if="otisPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>현장명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>CC/팀</i-col-header>
            <i-col>
              <iui-text name="ccName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>영업담당</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>계약일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix="~"
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>전자/서면</i-col-header>
            <i-col>
              <iui-select-old
                name="signType"
                :items="arrSignType"
                :value="search.signType"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <!-- <i-col-header>직영여부</i-col-header>
            <i-col>
              <iui-select-old
                name="directYn"
                :items="arrDirectYn"
                :value="search.directYn"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col colspan="3">
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col> -->
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="40px" ref="srchBox" v-if="customerPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>계약명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>계약일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix=" ~ "
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>전자/서면</i-col-header>
            <i-col>
              <iui-select-old
                name="signType"
                :items="arrSignType"
                :value="search.signType"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="260px">
      <i-col>
        <pmis-content-box no-border-box>
          <template v-slot:title v-if="isProgressPage">
            진행중 계약목록
          </template>
          <template v-slot:title v-else-if="isCompletePage">
            완료 계약목록
          </template>
          <template #title-right v-if="isProgressPage && otisPage">
            <iui-button :enable="isCanPDFGen" @click="onControlButton('pdfGen')">계약서 재전송</iui-button>
            <iui-button :enable="isCanReject" @click="onControlButton('reject')">반송/회수관리</iui-button>
            <iui-button :enable="isCanRecovery" @click="onControlButton('recovery')">회수</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
          <iui-modal-old :name="recoveryModalId" :title="'회수'" :btns="modalBtns1" width="400px" height="320px">
            <Recovery :ctrtNo="focusKeys.ctrtNo" :ctrtOdr="focusKeys.ctrtOdr" :ctrtDivCd="focusKeys.ctrtDivCd" />
          </iui-modal-old>
          <iui-modal-old
            :name="rejectModalId"
            :title="'OKSS전송/재전송'"
            :btns="modalBtns2"
            width="900px"
            height="400px"
          >
            <Reject :ctrtNo="focusKeys.ctrtNo" :ctrtOdr="focusKeys.ctrtOdr" :ctrtDivCd="focusKeys.ctrtDivCd" />
          </iui-modal-old>
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import Recovery from '../../components/popup/Recovery.vue';
import Reject from '../../components/popup/Reject.vue';
export default {
  name: 'o-contract-list',
  props: {
    progressPage: {
      // 진행중 페이지
      type: Boolean,
      default: false,
    },
    completePage: {
      // 계약완료목록 페이지
      type: Boolean,
      default: false,
    },
    otisPage: {
      // OTIS 페이지
      type: Boolean,
      default: false,
    },
    customerPage: {
      // 업체 페이지
      type: Boolean,
      default: false,
    },
  },
  components: {
    Recovery,
    Reject,
  },
  beforeCreate() {
    $mapGetters(this, ['contractInfo', 'isProgressPage', 'isCompletePage', 'isOfflineComplete', 'isContEmp']);
    $mapMutations(this, ['setProgressPage', 'setCompletePage']);
  },
  created() {
    this.addEvent({name: 'OContractList_Search', func: this.onPreSearch});
    this.setProgressPage(this.progressPage);
    this.setCompletePage(this.completePage);
  },
  data() {
    return {
      //sheetId: 'sheet',// + this._uid,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,
      modalNm: '',
      modalBtns1: [{name: '회수', callback: this.onPopupConfirm}],
      modalBtns2: [
        {name: 'OKSS전송', callback: this.onPopupConfirmSendToOkss},
        {name: '고객/OSEP 재전송', callback: this.onPopupConfirmReSend},
      ],
      recoveryModalId: 'recoveryModal' + this._uid,
      rejectModalId: 'rejectModal' + this._uid,

      arrDirectYn: [
        {text: '전체', value: ''},
        {text: '직영', value: 'DIRC'},
        {text: '공동수급', value: 'CONS'},
      ],
      arrStatusCode: [{text: '전체', value: ''}],
      arrSignType: [
        {text: '전체', value: ''},
        {text: '전자', value: 'ON'},
        {text: '서면', value: 'OFF'},
      ],
      search: {
        contractName: '',
        customerName: '',
        ccName: '',
        empName: '',
        checkContDateStart: '',
        checkContDateEnd: '',
        directYn: '',
        statusCode: '',
        isProgressPage: this.progressPage,
        isCompletePage: this.completePage,
        isOtisPage: this.otisPage,
        isCustomerPage: this.customerPage,
        summaryYm: '',
        delayYm: '',
        empNo: '',
        signType: 'ON',
        ctrtNo: '',
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        docCat1: '',
        signType: '',
      },

      modalOZViewerBtns: [],
      OZParam: {},
    };
  },
  mounted() {
    if (this.progressPage) {
      this.arrStatusCode.push({text: '업체송부', value: '10'});
      this.arrStatusCode.push({text: '구성원반송', value: '11'});
      this.arrStatusCode.push({text: '회수', value: '12'});
      this.arrStatusCode.push({text: 'OKSS송부', value: '13'});
      this.arrStatusCode.push({text: '고객반송', value: '14'});
      this.arrStatusCode.push({text: '구성원서명중', value: '20'});
    }

    if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
      if (this.customerPage) {
        this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -3);
      } else if (this.otisPage) {
        this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -1);
      }
      this.search.checkContDateEnd = $_getCurrentDate();
    }

    if (this.isCompletePage) {
      this.arrStatusCode.push({text: '계약완료', value: '30'});
      this.arrStatusCode.push({text: '공전소전송완료', value: '40'});
      this.arrStatusCode.push({text: '공전소전송실패', value: '41'});
    }

    const OPT = {
      Cols: [
        // eslint-disable-next-line
        {
          name: '보기',
          label: '보기',
          width: 50,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button >보기</button>' : '';
          },
          cellattr: $_rowMearge,
        },
        {name: 'docCat1Nm', label: '표준/비표준', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'signType', label: '온/오프라인', width: 0, hidden: true},
        {name: 'signTypeNm', label: '전자/서면', width: 60, align: 'center', cellattr: $_rowMearge},
        {name: 'contTeamNoDesc', label: 'CCT', width: 50, align: 'left', cellattr: $_rowMearge},
        {name: 'checkContTypeA3', label: '상품', width: 40, align: 'center'},
        {name: 'checkContGubun2Nm', label: '세부', width: 60, align: 'center'},
        {name: 'checkContGubunAu', label: '구분', width: 60, align: 'center', cellattr: $_rowMearge},
        {name: 'checkContNo', label: '번호', width: 80, align: 'center'},
        {name: 'checkSeq', label: '차수', width: 40, align: 'center'},
        {name: 'tradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'tradeName', label: '고객명', width: 170, align: 'left', cellattr: $_rowMearge},
        {name: 'minMainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'checkContNm', label: '현장명', width: 170, align: 'left', cellattr: $_rowMearge},
        {
          name: 'checkContDate',
          label: '계약일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContStartDate',
          label: '계약시작일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'checkContFinDate',
          label: '계약종료일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'supplyTotalAmt',
          label: '월보수료<br/>(VAT별도)',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        },
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'ctrtSttusCd', label: 'ctrtSttusCd', width: 0, hidden: true},
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
        {name: 'contractType', label: 'contractType', width: 0, hidden: true},
        {name: 'fileName', label: 'fileName', width: 0, hidden: true},
      ],
    };
    if (this.isProgressPage) {
      OPT.Cols.push({
        name: 'sttusRecovery',
        label: '회수',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusSend',
        label: '송부',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusSigning',
        label: '서명',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
      OPT.Cols.push({
        name: 'sttusReject',
        label: '반송',
        width: 50,
        align: 'center',
        formatter: function(cellvalue) {
          return cellvalue == '○' ? 'Ｏ' : '';
        },
      });
    }
    OPT.Cols.push({name: 'contEmpNm', label: '계약담당자', width: 70, align: 'center', cellattr: $_rowMearge});

    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      width: 1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      autowidth: false,
      shrinkToFit: false,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        console.log('iCol', iCol);
        console.log('rowId', rowId);
        console.log('cellContent', cellContent);
        console.log('e', e);
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        if (me.focusKeys.ctrtNo!=rowData.ctrtNo 
            || me.focusKeys.ctrtOdr!=rowData.ctrtOdr 
            || me.focusKeys.ctrtDivCd!=rowData.ctrtDivCd) {
          // 20211101 lgksja 보기일경우는 팝업만 열리게
          me.$emit('click', rowData);
        }
        me.focusKeys.ctrtNo = rowData.ctrtNo;
        me.focusKeys.ctrtOdr = rowData.ctrtOdr;
        me.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
        me.focusKeys.docCat1 = rowData.docCat1;
        me.focusKeys.signType = rowData.signType;

        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기']) {
          if (rowData.signType == 'ON') {
            me.onView(rowData);
          } else {
            me.onDownloadOfflinePdf(rowData);
          }
        }
      },
    });

    var groups = [];
    groups.push({startColumnName: 'checkContTypeA3', numberOfColumns: 2, titleText: '계약유형'});
    groups.push({startColumnName: 'checkContNo', numberOfColumns: 2, titleText: '계약번호'});
    if (this.isProgressPage) {
      groups.push({startColumnName: 'sttusRecovery', numberOfColumns: 4, titleText: '진행상태'});
    }
    $grid.jqGrid('setGroupHeaders', {
      useColSpanStyle: true,
      groupHeaders: groups,
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    // 20210407 검색란 보이기/감추기
    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$parent.$parent,
    });
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 2, false);
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.delayYm = '';
          this.search.empNo = '';

          this.search.statusCode = params.statusCode;
          this.search.summaryYm = params.summaryYm;
          this.search.delayYm = params.delayYm;
          this.search.empNo = params.empNo;
        }
      }

      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.focusKeys.docCat1 = '';
      this.focusKeys.signType = '';
      this.onSearch();
    },
    async onSearch() {
      if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
        // 20211111 lgksja 계약번호가 입력되면 계약일자와 무관하게 조회하도록
        if (this.search.ctrtNo === undefined || this.search.ctrtNo == '') {
          // 계약일 기간 비워져 있을시에 alert
          if (!this.search.checkContDateStart || !this.search.checkContDateEnd) {
            this.$alert('검색하실 계약일의 시작일과 종료일을 선택해주세요.');
            return;
          }

          if (parseInt(this.search.checkContDateStart) > parseInt(this.search.checkContDateEnd)) {
            this.$alert('계약일의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          var sDate = new Date(
            this.search.checkContDateStart.substring(0, 4),
            this.search.checkContDateStart.substring(4, 6),
            this.search.checkContDateStart.substring(6, 8)
          );

          var eDate = new Date(
            this.search.checkContDateEnd.substring(0, 4),
            this.search.checkContDateEnd.substring(4, 6),
            this.search.checkContDateEnd.substring(6, 8)
          );

          var dif = eDate - sDate;
          var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
          var cMonth = cDay * 30; // 월 만듬
          // var cYear = cMonth * 12; // 년 만듬

          // console.log('sDate', sDate);
          // console.log('eDate', eDate);
          // console.log('dif', dif);
          // console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));

          if (this.customerPage) {
            if (parseInt(dif / cMonth) > 3) {
              this.$alert('계약일 기간을 3개월 이내로 검색해주세요.');
              return;
            }
          } else if (this.otisPage) {
            if (parseInt(dif / cMonth) > 1) {
              this.$alert('계약일 기간을 1개월 이내로 검색해주세요.');
              return;
            }
          }
        } else {
          this.search.checkContDateStart = '';
          this.search.checkContDateEnd = '';
        }
      } else {
        this.search.checkContDateStart = '';
        this.search.checkContDateEnd = '';
      }

      this.listBoxLoading = true;

      let param = {
        searchGubun1: this.search.searchGubun1,
        searchText1: this.search.searchText1,
        contractName: this.search.contractName,
        customerName: this.search.customerName,
        ccName: this.search.ccName,
        empName: this.search.empName,
        checkContDateStart: this.search.checkContDateStart,
        checkContDateEnd: this.search.checkContDateEnd,
        directYn: this.search.directYn,
        statusCode: this.search.statusCode,
        progressPage: this.search.isProgressPage,
        completePage: this.search.isCompletePage,
        otisPage: this.search.isOtisPage,
        customerPage: this.search.isCustomerPage,
        summaryYm: this.search.summaryYm,
        delayYm: this.search.delayYm,
        empNo: this.search.empNo,
        signType: this.search.signType,
        ctrtNo: this.search.ctrtNo,
      };

      let response = null;
      try {
        response = await axios.post('/ocontract/selectContractList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              if (
                rowData['ctrtNo'] == this.focusKeys.ctrtNo &&
                rowData['ctrtOdr'] == this.focusKeys.ctrtOdr &&
                rowData['ctrtDivCd'] == this.focusKeys.ctrtDivCd
              ) {
                console.log('일치하는 Row 찾아 조회');
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.$emit('click', rowData);
                break;
              }
            }
          } else {
            console.log('일치하는 Row 못찾아 첫번째 행을 조회');
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.$emit('click', rowData);
          }
        } else {
          console.log('검색된 데이터 없어서 리셋');
          this.$emit('resetValues', null);
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    async onControlButton(type) {
      const me = this;

      console.log('ctrtNo', me.contractInfo.ctrtNo);
      console.log('ctrtOdr', me.contractInfo.ctrtOdr);
      console.log('ctrtDivCd', me.contractInfo.ctrtDivCd);
      console.log('ctrtSttusCd', me.contractInfo.ctrtSttusCd);

      me.focusKeys.ctrtNo = me.contractInfo.ctrtNo;
      me.focusKeys.ctrtOdr = me.contractInfo.ctrtOdr;
      me.focusKeys.ctrtDivCd = me.contractInfo.ctrtDivCd;
      me.focusKeys.docCat1 = me.contractInfo.docCat1;

      console.log('SCD 관리자', $isAdmin());
      console.log('계약담당자', this.isContEmp);

      if (!($isAdmin() || this.isContEmp)) {
        this.$alert('SCD 관리자 또는 계약담당자만 해당 업무를 수행할 수 있습니다.');
        return;
      }

      // 업체송부 : 10
      // 구성원반송 : 11
      // 회수 : 12
      // OKSS송부 : 13
      // 구성원서명중 : 20
      // 계약완료 : 30
      // 공전소전송완료 : 40
      // 공전소전송실패 : 41
      var ctrtSttusCd = me.contractInfo.ctrtSttusCd;

      if (this.isOfflineComplete) {
        this.$alert('선택된 계약은 서면으로 진행한 계약이라 시스템에서 업무진행을 할 수 없습니다.');
        return;
      }

      if (type == 'pdfGen') {
        if (me.focusKeys.docCat1 == '000') {
          this.$alert('선택된 계약은 비표준계약이라 계약서 재전송을 할 수 없습니다.');
          return;
        }

        if (!(ctrtSttusCd == '10')) {
          this.$alert('선택된 계약은 계약서 재전송을 할 수 없는 상태입니다.');
          return;
        }

        if (await me.$confirm('계약서 재전송을 하시겠습니까?')) {
          let param = {
            ctrtNo: me.contractInfo.ctrtNo,
            ctrtOdr: me.contractInfo.ctrtOdr,
            ctrtDivCd: me.contractInfo.ctrtDivCd,
            reason: '계약서 재전송',
            chngPgmId: me.$store.getters['mdi/currentUrls'].url,
          };

          try {
            me.listBoxLoading = true;
            await axios.post('/contract/reSend', param);
            me.listBoxLoading = false;
            me.$alert('계약서 재전송이 완료되었습니다.');
            me.onSearch();
          } catch (ex) {
            me.listBoxLoading = false;
            me.$alert('계약서 재전송이 실패하였습니다.' + e);
          }
        }
      } else if (type == 'reject') {
        if (!(ctrtSttusCd == '11' || ctrtSttusCd == '12' || ctrtSttusCd == '14')) {
          this.$alert('선택된 계약은 반송 또는 회수 할 수 없는 상태입니다.');
          return;
        }

        me.onOpenPopup(this.rejectModalId);
      } else if (type == 'recovery') {
        if (!(ctrtSttusCd == '10' || ctrtSttusCd == '20')) {
          this.$alert('선택된 계약은 회수 할 수 없는 상태입니다.');
          return;
        }

        me.onOpenPopup(this.recoveryModalId);
      }
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onPopupConfirm() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.recoveryModalId) {
        this.callEvent({
          name: 'Recovery_onSave',
          param: data => {
            console.log('Recovery_onSave');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    onPopupConfirmSendToOkss() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.rejectModalId) {
        console.log('sendToOkss');
        this.callEvent({
          name: 'Reject_SendToOkss',
          param: data => {
            console.log('Reject_SendToOkss');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    onPopupConfirmReSend() {
      console.log('modalNm', this.modalNm);
      if (this.modalNm == this.rejectModalId) {
        console.log('reSend');
        this.callEvent({
          name: 'Reject_ReSend',
          param: data => {
            console.log('Reject_ReSend');
            console.log(data);
            this.$modal.hide(this.modalNm);
            this.onSearch();
          },
        });
      }
    },
    async onDownloadOfflinePdf(data) {
      var fileName = data.fileName;
      var url = '/ocontract/downloadOfflinePdf?checkContNo=' + data.ctrtNo + '&checkSeq=' + data.ctrtOdr;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
      }
    },
    async onView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);
      // this.$alert('계약서 보기 팝업.');
      
      let param2 = {
        ctrtNo: data.ctrtNo,
        ctrtOdr: data.ctrtOdr,
        ctrtDivCd: data.ctrtDivCd,
      };
      let response2 = await axios.post('/ocontract/selectSignerInfo', param2);

      console.log(response2);

      var signable = 'N';
      var signerSeCd =
        eval(response2.data.buyagnt) == true ? '20' : eval(response2.data.customer) == true ? '10' : '30';
      var tradeNo =
        eval(response2.data.buyagnt) == false && eval(response2.data.canSign) == true ? response2.data.tradeNo : '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약서 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      var opt = {};
      const btns = [];

      //20221123 ITSR62079
      var opt_param = {
                    ctrtNo: data.ctrtNo,
                    ctrtOdr: data.ctrtOdr,
                    ctrtDivCd: data.ctrtDivCd,
                    docCat1: data.docCat1,
                    ctrtSttusCd: data.ctrtSttusCd,
                  }; 
      if (
        eval(response2.data.buyagnt) == false &&
        this.customerPage &&
        eval(response2.data.customer) == false &&
        eval(response2.data.canSign) == true &&
        (data.ctrtSttusCd == '10' || data.ctrtSttusCd == '20')
      ) {
        btns.push({
          name: '반송', // 버튼명
          callback: () => {
            // 버튼 Event
            popupInterface[opt.name].vue.callEvent({
              name: 'OContractViewerPopup_Reject',
              param: data => {
                console.log('OContractViewerPopup_Reject');
                console.log(data);
                opt.window.close();
                this.$alert('반송이 완료되었습니다.');
                this.onSearch();
              },
            });
          },
        });
      }
      if (
        eval(response2.data.buyagnt) == false &&
        this.customerPage &&
        eval(response2.data.customer) == true &&
        eval(response2.data.canSign) == true &&
        (data.ctrtSttusCd == '10' || data.ctrtSttusCd == '20')
      ) {
        btns.push({
          name: '반송', // 버튼명
          callback: () => {
            // 버튼 Event
            popupInterface[opt.name].vue.callEvent({
              name: 'OContractViewerPopup_RejectByCust',
              param: data => {
                console.log('OContractViewerPopup_RejectByCust');
                console.log(data);
                opt.window.close();
                this.$alert('반송이 완료되었습니다.');
                this.onSearch();
              },
            });
          },
        });
      }
      if (
        eval(response2.data.buyagnt) == false &&
        this.customerPage &&
        eval(response2.data.canSign) == true &&
        (data.ctrtSttusCd == '10' || data.ctrtSttusCd == '20')
      ) {
        btns.push({
          name: '서명', // 버튼명
          callback: () => {
            // 버튼 Event
            popupInterface[opt.name].vue.callEvent({
              name: 'OContractViewerPopup_Sign',
              param: data => {
                console.log('OContractViewerPopup_Sign');
                console.log(data);
                opt.window.close();
                this.$alert('서명이 완료되었습니다.');
                this.onSearch();
              },
            });
          },
        });
      }
      console.log('btns', btns);

      if (data.docCat1 == '000') {
        // eslint-disable-next-line
        opt = $open({
          componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
          param: {
            //ctrtNo: this.focusKeys.ctrtNo,
            //ctrtOdr: this.focusKeys.ctrtOdr,
            //ctrtDivCd: this.focusKeys.ctrtDivCd,
            //docCat1: this.focusKeys.docCat1,
            ...opt_param,
            OZParam: {},
            otisPage: this.otisPage,
          }, // 파라미터 (생략가능)
          btns: btns, // 팝업 버튼 (생략가능)
          name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
          option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
          title: '계약서조회', // 팝업 제목 (생략가능)
        });
      } else {
        if(['30', '40', '41'].includes(data.ctrtSttusCd)){ //서명완료일경우 pdf뷰로 바로 보이게게 20250102
          opt = $open({
                componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  ...opt_param,
                  OZParam: {},
                  otisPage: this.otisPage,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '계약서조회', // 팝업 제목 (생략가능)
              });
        }else{
          axios
            .post('/ozrpt/selectOzOContract', param)
            .then(response => {
              // console.log('조회결과 response >>>', response);
              // console.log('조회결과 response.data >>>', response.data);

              this.OZParam = Object.assign({}, response.data.jsonData);

              this.OZParam.reportname = response.data.reportPathName;
              this.OZParam.OZFormParam = {
                signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                signMthCd: '',
              };

              // eslint-disable-next-line
              opt = $open({
                componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                param: {
                  //ctrtNo: this.focusKeys.ctrtNo,
                  //ctrtOdr: this.focusKeys.ctrtOdr,
                  //ctrtDivCd: this.focusKeys.ctrtDivCd,
                  //docCat1: this.focusKeys.docCat1,
                  ...opt_param,
                  OZParam: this.OZParam,
                  otisPage: this.otisPage,
                }, // 파라미터 (생략가능)
                btns: btns, // 팝업 버튼 (생략가능)
                name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                title: '계약서조회', // 팝업 제목 (생략가능)
              });
            })
            .catch(function(error) {
              console.log('========error====================');
              console.log(error);
            });
        }
      }
    },
  },
  computed: {
    isCanPDFGen() {
      return this.isProgressPage == true && this.focusKeys.signType == 'ON';
    },
    isCanReject() {
      return this.isProgressPage == true && this.focusKeys.signType == 'ON';
    },
    isCanRecovery() {
      return this.isProgressPage == true && this.focusKeys.signType == 'ON';
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
