<script>
import {defineComponent, reactive, ref, computed, onMounted, onUnmounted, watch, nextTick} from '@vue/composition-api';
import moment from 'moment';

const ibsheetOptions1 = {
  Def : {
    Row: {
      CanFormula: 1,
      ShowHint: 0,
      Tip: 1,
    }
  },
  Cfg: {
    CustomScroll: 1,
    Down2ExcelConfig: {
      downCols: 'Visible',
      excelFontSize: 11,
      merge: 1,
    },
    Export: {
      Url: process.env.VUE_APP_BASE_URL + '/ibsheet/',
      Down2ExcelUrl: process.env.VUE_APP_BASE_URL + '/ibsheet/downloadExcel',
    },
    FitWidth: true,
    CanEdit: 0,
    Hover: 0,  
    IgnoreFocused: true,        // 조회 후 포커스를 가져오지 않게 한다.
    SearchMode: 0,
    //Alternate: 2,
    //CanSort: true,
    // "HeaderCheck": 0,
    // "Hover": 0,
    // "InfoRowConfig": {
    //   "Visible": false
    // },
    ShowFilter: true,
    FormulaRowNoFiltered: true // 필터링되어 안보이는 로우를 합계 계산에서 제외합니다.
  },
  LeftCols: [
    { Name: 'SEQ'                , Header: ' '                    , Type: 'Int'  , Width: 50 , },
    { Name: 'division'           , Header: 'Division'             , Type: 'Text' , Width: 80, Align: 'Center',},
    { Name: 'salesNm'            , Header: 'SalesNm'              , Type: 'Text' , Width: 90, Align: 'Left'  , },
  ],
  Cols: [
    { Name: 'signType'           , Header: 'SignType'            , Type: 'Text' , Width: 80, Align: 'Center', },
    { Name: 'eContract'          , Header: 'eContract%'            , Type: 'Int' , Width: 80, Align: 'right', Formula: "(signType =='ON') ? 100 : 0",Format: "##0.00 \\%",FormulaRow: "Avg"},
    { Name: 'period'             , Header: 'Period'               , Type: 'Text' , Width: 80, Align: 'Center', },
    { Name: 'offReason'          , Header: 'OffReason'            , Type: 'Text' , Width: 200, Align: 'Center', },
    { Name: 'mainNo'             , Header: 'MainNo'               , Type: 'Text' , Width: 90, Align: 'Left'  , },
    { Name: 'equipCnt'           , Header: 'EquipCnt'             , Type: 'Int'  , Width: 80, Align: 'right' , FormulaRow: 'Sum',},
    { Name: 'directNm'           , Header: 'DO'                  , Type: 'Text' , Width: 60, Align: 'Left'  , Formula: "(direct =='TM') ? 'Direct' : 'OSEP'",},
    { Name: 'direct'             , Header: 'Direct'               , Type: 'Text' , Width: 60, Align: 'Left'  , },
    { Name: 'contTypeNm'         , Header: 'ContTypeNm'           , Type: 'Text' , Width: 100, Align: 'Center', },
    { Name: 'contractNo'         , Header: 'ContractNo'           , Type: 'Text' , Width: 120, Align: 'Right' , },
    { Name: 'contractNm'         , Header: 'ContractNm'           , Type: 'Text' , Width: 150, Align: 'Right' , },

    { Name: 'contFlag'           , Header: 'ContFlag'             , Type: 'Text' , Width: 100, Align: 'Center', },
    { Name: 'contractSeq'        , Header: 'ContractSeq'          , Type: 'Text' , Width: 60, Align: 'Right' , },
    { Name: 'contFinDtYear'      , Header: 'Year'                 , Type: 'Text' , Width: 60, Align: 'Center',  },
    { Name: 'contFinDtQuarter'   , Header: 'Quarter'              , Type: 'Text' , Width: 70, Align: 'Center',  },
    { Name: 'contFinDtMonth'     , Header: 'Month'                , Type: 'Text' , Width: 100, Align: 'Center',  },
    { Name: 'contFinDtDay'       , Header: 'Day'                  , Type: 'Text' , Width: 50, Align: 'Center', },
    { Name: 'totalContract'      , Header: 'TotalContract'        , Type: 'Int'  , Width: 50, Align: 'right' , Formula: 1 ,FormulaRow: "Count",},
    { Name: 'divCd'              , Header: 'Type'                 , Type: 'Text' , Width: 60, Align: 'Center', },
  ],
  Events: {
   
  },
  Filter: {
    "signType": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    }
    ,
    "period": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "offReason": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "directNm": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },   
    "direct": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },   
    "contTypeNm": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contFlag": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "divCd": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "division": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "salesNm": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contFinDtYear": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contFinDtQuarter": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contFinDtMonth": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contFinDtDay": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    }
  }
};

const ibsheetOptions2 = {
  Def : {
    Row: {
      CanFormula: 1,
      ShowHint: 0,
      Tip: 1,
    }
  },
  Cfg: {
    CustomScroll: 1,
    Down2ExcelConfig: {
      downCols: 'Visible',
      excelFontSize: 11,
      merge: 1,
    },
    Export: {
      Url: process.env.VUE_APP_BASE_URL + '/ibsheet/',
      Down2ExcelUrl: process.env.VUE_APP_BASE_URL + '/ibsheet/downloadExcel',
    },
    FitWidth: true,
    CanEdit: 0,
    Hover: 0,  
    IgnoreFocused: true,        // 조회 후 포커스를 가져오지 않게 한다.
    SearchMode: 0,
    // "HeaderCheck": 0,
    // "Hover": 0,
    // "InfoRowConfig": {
    //   "Visible": false
    // },
    ShowFilter: true,
    FormulaRowNoFiltered: true // 필터링되어 안보이는 로우를 합계 계산에서 제외합니다.
  },
  LeftCols: [
    { Name: 'SEQ'                , Header: ' '                    , Type: 'Int'  , Width: 50 , },
    { Name: 'contTypeNm'         , Header: 'Category'             , Type: 'Text' , Width: 80, Align: 'Center',},
    { Name: 'salesNm'            , Header: 'EmpName'              , Type: 'Text' , Width: 90, Align: 'Left'  , },
  ],
  Cols: [
    { Name: 'contractNo'         , Header: 'ContractNo'           , Type: 'Text' , Width: 130, Align: 'Center', },
    { Name: 'offReason'          , Header: 'Depart'                , Type: 'Text' , Width: 80, Align: 'Center', },
    { Name: 'direct'             , Header: 'Direct'               , Type: 'Text' , Width: 60, Align: 'Left'  , },
    { Name: 'division'           , Header: 'Division'             , Type: 'Text' , Width: 80, Align: 'Center',},

    { Name: 'eSign'               , Header: 'eSign%'            , Type: 'Int' , Width: 80, Align: 'right', Formula: "(signType =='ON') ? 100 : 0",Format: "##0.00 \\%",FormulaRow: "Avg"},
    { Name: 'contractNm'         , Header: 'MainName'           , Type: 'Text' , Width: 200, Align: 'Left' , },
    { Name: 'mainNo'             , Header: 'MainNo'               , Type: 'Text' , Width: 90, Align: 'Right'  , },

    { Name: 'period'             , Header: 'Period'               , Type: 'Text' , Width: 80, Align: 'Center', },
    { Name: 'signType'           , Header: 'SignType'            , Type: 'Text' , Width: 80, Align: 'Center', },
    { Name: 'totalSign'      , Header: 'TotalSign'              , Type: 'Int'  , Width: 50, Align: 'right' , Formula: 1 ,FormulaRow: "Count",},
    { Name: 'divCd'              , Header: 'Type'                 , Type: 'Text' , Width: 60, Align: 'Center', },    
   
  ],
  Events: {},
  Filter: {
    "signType": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    }
    ,
    "period": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "offReason": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "contTypeNm": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },   
    "direct": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },   
    "salesNm": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "divCd": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
    "division": {
      "Button": "Defaults",
      "Defaults": "|*Rows"
    },    
  }
};

const CHART = {
  XAXIS: {
    labels: {
      allowOverlap: true,
      rotation: 0,
      style: {
        fontSize: '12px',
      },
      overflow: 'allow',
    },
    lineColor: '#000000',
    lineWidth: 1,
  },
  YAXIS_COLUMN: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    lineWidth: 0,
    min: 0,
    max: 200,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  YAXIS_LINE: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    min: -150,
    max: 100,
    lineWidth: 0,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  YAXIS_BAR: {
    title: {text: '활용율(%)', enabled: false},
    labels: {
      enabled: false,
      format: '{value}%',
    },
    lineWidth: 0,
    min: 0,
    max: 140,
    // showLastLabel: false,
    // opposite: true,
    // tickInterval: 10,
  },
  SERIES_COLUMN: {
    type: 'column',
    //colors: ['#FFF263', '#6AF9C4'],
    dataLabels: {
      enabled: true,
      align: 'center',
      color: '#000000',
      format: '{point.y:.0f}%',
      shadow: false,
      style: {
        fontSize: '12px',
        // textOutline: '1px contrast',
      },
      verticalAlign: 'middle',
      //y: -22,
    },
    // minPointLength: 3,
    shadow: false,
    yAxis: 0,
  },
  SERIES_LINE: {
    type: 'line',
    color: '#f65275',
    dataLabels: {
      enabled: true,
      align: 'center',
      format: '{point.y:.0f}%',
      style: {
        fontSize: '12px',
      },
    },
    tooltip: {
      valueSuffix: '%',
    },
    yAxis: 1,
  },
  SERIES_BAR: {
    type: 'bar',
    dataLabels: {
      enabled: true,
      align: 'center',
      color: '#000000',
      format: '{point.y:.0f}%',
      shadow: false,
      style: {
        fontSize: '12px',
        // textOutline: '1px contrast',
      },
      verticalAlign: 'middle',
      //y: -22,
    },
    // minPointLength: 3,
    shadow: false,
    yAxis: 0,
  },
};

export default defineComponent({
  setup(props, context) {

    const store = context.root.$store;
    console.log(store.state);

    let menus ={
              "orderSeq": 3,
              "menuGubun": "P",
              "parentMenuId": "MNU00385",
              "webUrl": "./view/statistics/userate/svc/ose.vue",
              "buttonId": "NNYNNNNNNNN",
              "menuId": "MNU00734",
              "helpUrl": "",
              "menuName": "(SVC)전자계약 활용율",
              "helpTip": "",
              "programId": "PGM00253",
              "vwYn": "Y",
              "userVwYn": "Y",
              "svYn": "N",
              "userSvYn": "N",
              "delYn": "N",
              "userDelYn": "N",
              "prnYn": "N",
              "userPrnYn": "N",
              "excelYn": "N",
              "userExcelYn": "N",
              "isSelected": true,
              "rootMenuId": "MNU00365",
              "url": "/MNU00734"
          };
    //store.commit('setCurrentUrls', menus);
    store.commit("mdi/setCurrentUrls", menus);
    
    const divList = [];
    const parentTeamList = [];
    let teamList = [];
    /*
    const useRateTemplate = {
      period: undefined,
      onlineCount: 0,
      offlineCount: 0,
      totalCount: 0,
      onlineRate: 0,
      offlineRate: 0,

      offReason : [],
    };
    */
    const useRateTemplate = {
      tabCf:{
        SVC_O : {
                  period: undefined,
                  onlineCount: 0,
                  offlineCount: 0,
                  totalCount: 0,
                  onlineRate: 0,
                  offlineRate: 0,

                  offReason : [],
                },
        SVC_T : {
                period: undefined,
                onlineCount: 0,
                offlineCount: 0,
                totalCount: 0,
                onlineRate: 0,
                offlineRate: 0,

                offReason : [],
              }
      },
    };

    // prettier-ignore
    // eslint-disable-next-line no-constant-condition
    if (true) {
      divList.push({divCd: 'OC', pageList: [1], teamNoList: ['OC'], teamNo: 'OC', teamName: '점검계약'});
      divList.push({divCd: 'TC', pageList: [1], teamNoList: ['TC'], teamNo: 'TC', teamName: '수리공사'});
      divList.push({divCd: 'TT', pageList: [1], teamNoList: ['TT'], teamNo: 'TT', teamName: '점검/수리'});

      divList.push({divCd: 'OS', pageList: [1], teamNoList: ['OS'], teamNo: 'OS', teamName: 'OSEP'});
      divList.push({divCd: 'TM', pageList: [1], teamNoList: ['TM'], teamNo: 'TM', teamName: 'Direct'});
      divList.push({divCd: 'OO', pageList: [1], teamNoList: ['OO'], teamNo: 'OO', teamName: 'Direct/OSEP'});


      divList.push({divCd: 'OC', pageList: [2], teamNoList: ['OC'], teamNo: 'OC', teamName: 'Repair'});
      divList.push({divCd: 'TC', pageList: [2], teamNoList: ['TC'], teamNo: 'TC', teamName: 'Part'});
      divList.push({divCd: 'TT', pageList: [2], teamNoList: ['TT'], teamNo: 'TT', teamName: 'Repair/Part'});

      divList.push({divCd: 'OS', pageList: [2], teamNoList: ['OS'], teamNo: 'OS', teamName: 'OSEP'});
      divList.push({divCd: 'TM', pageList: [2], teamNoList: ['TM'], teamNo: 'TM', teamName: 'Direct'});
      divList.push({divCd: 'OO', pageList: [2], teamNoList: ['OO'], teamNo: 'OO', teamName: 'Direct/OSEP'});

      //OC
      parentTeamList.push({divCd: 'OC', pageList: [], teamNo: 'OC', teamName: '점검계약'});

      // OC 
      //teamList.push({divCd: 'OC', pageList: [1], parentTeamNo: 'OC', parentTeamName: '점검계약', teamNoList: ['O_강남'], teamNo: 'O_강남', teamName: '강남'});
      //teamList.push({divCd: 'OC', pageList: [1], parentTeamNo: 'OC', parentTeamName: '점검계약', teamNoList: ['O_강북'], teamNo: 'O_강북', teamName: '강북'});
      //teamList.push({divCd: 'OC', pageList: [1], parentTeamNo: 'OC', parentTeamName: '점검계약', teamNoList: ['O_강서'], teamNo: 'O_강서', teamName: '강서'});
      //teamList.push({divCd: 'OC', pageList: [1], parentTeamNo: 'OC', parentTeamName: '점검계약', teamNoList: ['O_남부'], teamNo: 'O_남부', teamName: '남부'});      
      //teamList.push({divCd: 'OC', pageList: [1], parentTeamNo: 'OC', parentTeamName: '점검계약', teamNoList: ['O_중부'], teamNo: 'O_중부', teamName: '중부'});

      //TC
      parentTeamList.push({divCd: 'TC', pageList: [], teamNo: 'TC', teamName: '수리공사'});

      //TC 
      //teamList.push({divCd: 'TC', pageList: [1], parentTeamNo: 'TC', parentTeamName: '수리공사', teamNoList: ['T_강남'], teamNo: 'T_강남', teamName: '강남'});
      //teamList.push({divCd: 'TC', pageList: [1], parentTeamNo: 'TC', parentTeamName: '수리공사', teamNoList: ['T_강북'], teamNo: 'T_강북', teamName: '강북'});
      //teamList.push({divCd: 'TC', pageList: [1], parentTeamNo: 'TC', parentTeamName: '수리공사', teamNoList: ['T_강서'], teamNo: 'T_강서', teamName: '강서'});
      //teamList.push({divCd: 'TC', pageList: [1], parentTeamNo: 'TC', parentTeamName: '수리공사', teamNoList: ['T_남부'], teamNo: 'T_남부', teamName: '남부'});      
      //teamList.push({divCd: 'TC', pageList: [1], parentTeamNo: 'TC', parentTeamName: '수리공사', teamNoList: ['T_중부'], teamNo: 'T_중부', teamName: '중부'});

      //전체
      parentTeamList.push({divCd: 'TT', pageList: [], teamNo: 'TT', teamName: '점검/수리'});

      //전체
      //teamList.push({divCd: 'TT', pageList: [1], parentTeamNo: 'TT', parentTeamName: '점검/수리', teamNoList: ['강남'], teamNo: '강남', teamName: '강남'});
      //teamList.push({divCd: 'TT', pageList: [1], parentTeamNo: 'TT', parentTeamName: '점검/수리', teamNoList: ['강북'], teamNo: '강북', teamName: '강북'});
      //teamList.push({divCd: 'TT', pageList: [1], parentTeamNo: 'TT', parentTeamName: '점검/수리', teamNoList: ['강서'], teamNo: '강서', teamName: '강서'});
      //teamList.push({divCd: 'TT', pageList: [1], parentTeamNo: 'TT', parentTeamName: '점검/수리', teamNoList: ['남부'], teamNo: '남부', teamName: '남부'});      
      //teamList.push({divCd: 'TT', pageList: [1], parentTeamNo: 'TT', parentTeamName: '점검/수리', teamNoList: ['중부'], teamNo: '중부', teamName: '중부'});
      
      parentTeamList.push({divCd: 'OS', pageList: [], teamNo: 'OS', teamName: 'OSEP'});
      parentTeamList.push({divCd: 'TM', pageList: [], teamNo: 'TM', teamName: 'Direct'});
      parentTeamList.push({divCd: 'OO', pageList: [], teamNo: 'OO', teamName: 'Direct/OSEP'});
    }

    /*
    const startDate = '20220901';
    let fromDate = moment()
      .startOf('month')
      .format('YYYYMMDD');
    let toDate = moment(startDate)
      .endOf('month')
      .add(6, 'month')
      .format('YYYYMMDD');

    if (toDate < fromDate) {
      toDate = moment(fromDate)
        .endOf('month')
        .format('YYYYMMDD');
      fromDate = moment(toDate)
        .startOf('month')
        .add(-6, 'month')
        .format('YYYYMMDD');
    } else {
      fromDate = startDate;
    }
    */
    let toDate = moment()
                .format('YYYY-MM-DD')
    let fromDate = moment().subtract(1,'years').format('YYYY-MM-DD'); //일년 전

    const checkBoxList = {
      direct: [
        {text: 'Direct', value: 'TM'},
        {text: 'OSEP', value: 'OS'},
      ],

     
      contFlag:{
        SVC_O : [],
        SVC_T : []
      },
      period:{
        SVC_O : [],
        SVC_T : []
      },

      offReason : {
        SVC_O : [],
        SVC_T : []
      }
        
      
    };

    const state = reactive({
      searchData: {
        divCd: 'SVC_O',
        fromDate: fromDate,
        toDate: toDate,
        fromPeriod: undefined,
        toPeriod: undefined,
        useRateBaseDate: 'ENTRY_DATE',
        direct:'',
        contFlag:undefined,
        period: [],
      },

      codeList: {
        columnSizeList: [
          {key: '2', value: '2개'},
          {key: '3', value: '3개'},
          {key: '4', value: '4개'},
          {key: '5', value: '5개'},
          {key: '6', value: '6개'},
        ],
        useRateBaseDate: [
          {key: 'ENTRY_DATE', value: '수주등록일'},
          {key: 'CONT_DATE', value: '계약일'},
        ],
      },

      periodList: undefined,
      areaList: undefined,
      // parentTeamList: {all: undefined, ne: undefined, mod: undefined},
      // teamList: {all: undefined, ne: undefined, mod: undefined},
      // seriesByPeriod: {all: undefined, ne: undefined, mod: undefined},
      // seriesByParentTeam: {all: undefined, ne: undefined, mod: undefined},
      // seriesByTeam: {all: undefined, ne: undefined, mod: undefined},

      ocPage1ColumnSize: {
        SVC_O : 4,
        SVC_T : 4
      },
      osPage1ColumnSize: {
        SVC_O : 4,
        SVC_T : 4
      },

      ocPage1ColumnWidth: computed(() => {
        const width = 100 / state.ocPage1ColumnSize[state.searchData.divCd];

        return `${width}%`;
      }),

      osPage1ColumnWidth: computed(() => {
        const width = 100 / state.osPage1ColumnSize[state.searchData.divCd];

        return `${width}%`;
      }),

      tabTitle: computed(() => {
        //console.log("state.searchData.divCd",state.searchData.divCd);
        const title = (state.searchData.divCd == 'SVC_T') ? ['Part','Repair'] :  ['점검','수리'];
        //console.log("title",title);
        //console.log("state.searchData.divCd",state.searchData.divCd);
        return title;
      }),

      ocTeamList : {
        SVC_O : [],
        SVC_T : []
      },
      // ocTeamList: computed(() => {
      //   return teamList.filter(team => team.divCd === 'OC');
      // }),

      ocPage1TeamList : {
        SVC_O : [],
        SVC_T : []
      },
      // ocPage1TeamList: computed(() => {
      //   console.log("aaaaaaaaaaaaaaaaaaaaa");
      //   return [
      //     ...divList.filter(div => div.divCd === 'OC' && div.pageList.includes(1)),
      //     ...teamList.filter(team => team.divCd === 'OC' && team.pageList.includes(1)),
      //   ];
      // }),
      
      tcTeamList : {
        SVC_O : [],
        SVC_T : []
      },
      // tcTeamList: computed(() => {
      //   return teamList.filter(team => team.divCd === 'TC');
      // }),
      tcPage1TeamList : {
        SVC_O : [],
        SVC_T : []
      },
      // tcPage1TeamList: computed(() => {
      //   return [
      //     ...divList.filter(div => div.divCd === 'TC' && div.pageList.includes(1)),
      //     ...teamList.filter(team => team.divCd === 'TC' && team.pageList.includes(1)),
      //   ];
      // }),

      
      totalTeamList : {
        SVC_O : [],
        SVC_T : []
      },
      /*
      totalTeamList: computed(() => {
        return teamList.filter(team => team.divCd === 'TT');
      }),
      */

      totalPage1TeamList : {
        SVC_O : [],
        SVC_T : []
      },
      /*
      totalPage1TeamList: computed(() => {
        return [
          ...divList.filter(div =>  div.divCd === 'TT' && div.pageList.includes(1)),
          ...teamList.filter(team =>  team.divCd === 'TT' && team.pageList.includes(1)),
        ];
      }),
      */
      osTeamList : {
        SVC_O : [],
        SVC_T : []
      },
      osPage1TeamList : {
        SVC_O : [],
        SVC_T : []
      },      
      tmTeamList : {
        SVC_O : [],
        SVC_T : []
      },
      tmPage1TeamList : {
        SVC_O : [],
        SVC_T : []
      },    
      totalmTeamList: {
        SVC_O : [],
        SVC_T : []
      },

      totalmPage1TeamList: {
        SVC_O : [],
        SVC_T : []
      },

      loading: false,
      loadingMessage: undefined,

     
      multiValueDirect:{
        SVC_O : ['TM','OS'],
        SVC_T : ['TM','OS']
      },
      multiValueContFlag:{
        SVC_O : [],
        SVC_T : []
      },
      multiValuePeriod:{
        SVC_O : [],
        SVC_T : []
      },

      dataCall:true,
      initData : {
        SVC_O : [],
        SVC_T : []
      },

      multiValueOffReason:{
        SVC_O : [],
        SVC_T : []
      },


      grid1: undefined,
      gridOptions1: ibsheetOptions1,
      list1: undefined,

      grid2: undefined,
      gridOptions2: ibsheetOptions2,
      list2: undefined,

      
    });    

    state.gridOptions1.Events = {
      ...state.gridOptions1.Events,
      async onDataLoad() {
        //state.loading = false; // 로딩 상태 완료로 변경
        await nextTick();
        state.loading = false;
        state.loadingMessage = '데이터를 가져오고 있습니다. 3/4';
        //await nextTick();
        state.loading = true;
        try {
          state.searchData.divCd = 'SVC_TE';
          const response = await axios.post('/statistics/useRateOse', state.searchData);
         
          //
          state.loading = false;
          state.loadingMessage = '데이터를 가져오고 있습니다.-데이타분석중 2/4';
          state.loading = true;
          await nextTick();
          TeCompletionSheetId.loadSearchData( response.data, 1 );
          //state.loading = true;
          // setTimeout(async function(){
          //   state.list1 = response.data;
          // },100);
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        } finally {
          //state.loading = false;
        }
      },
    };

    state.gridOptions2.Events = {
      ...state.gridOptions2.Events,
      onDataLoad() {
        state.loading = false; // 로딩 상태 완료로 변경
      },
    };

    const privateMethod = {
      inputNumberFormat(val){
            return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      getChartId(team, page) {
        return `divChart_${team.divCd}_1_${team.teamNo}_${page}`;
      },
      calcurateUseRate(useRate) {
        useRate.totalCount = useRate.onlineCount + useRate.offlineCount;
        useRate.onlineRate = Math.round((useRate.onlineCount / useRate.totalCount) * 100);
        useRate.offlineRate = Math.round((useRate.offlineCount / useRate.totalCount) * 100);
      },
      columnDataLabelsFormatter(data, team) {
        return `${team.useRateList[data.point.x].onlineCount} / ${team.useRateList[data.point.x].totalCount}`;
      },
      tooltipFormatter(data, team) {
        return (
          `<b><i>${team.useRateList[data.point.x].tooltipPeriod}</i></b>` +
          `<hr size="1" />` +
          `<b>${data.series.name}: ${data.point.y}</b>%` +
          `<hr size="1" />` +
          `전자계약: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].onlineCount}`) + `건</b><br />` +
          `서면계약: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].offlineCount}`) + `건</b><br />` +
          `<b>전체계약</b>: <b>` + privateMethod.inputNumberFormat(`${team.useRateList[data.point.x].totalCount}`) + `건</b>`
        );
      },
      tooltipFormatter2(data, ocTeam,tcTeam,totalTeam) {
       
        let tt =[];
        let aa = undefined;
        //console.log("data",data);
        //console.log("ocTeam",ocTeam);
        //console.log("tcTeam",tcTeam);
        //console.log("totalTeam",totalTeam);
        //console.log("(state.searchData.divCd",(state.searchData.divCd));
        if(data.series.name.indexOf("점검") > -1 || data.series.name.indexOf("OSEP") > -1 || data.series.name.indexOf("Part") > -1){
          tt = ocTeam;
          aa = (state.searchData.divCd =='SVC_O') ? tt.useRateList[data.point.x].offReason : undefined;
          //console.log("aaaa");
        }else if(data.series.name.indexOf("수리") > -1 || data.series.name.indexOf("Direct") > -1 || data.series.name.indexOf("Repair") > -1){
          tt = tcTeam;
          aa = (state.searchData.divCd =='SVC_O') ?  tt.useRateList[data.point.x].offReason : undefined;
          //console.log("bbbb");
        }else{
          //console.log("cccc")
          tt = totalTeam;
          let bb = [];
          if (state.searchData.divCd =='SVC_O') {
            $.each(state.multiValueOffReason['SVC_O'], function(index, element){
              if(element != undefined){
              
                if (typeof bb[element] == 'undefined' ){
                  bb[element] = 0
                }
                if (typeof ocTeam.useRateList[data.point.x].offReason[element] != 'undefined' ){
                  bb[element] +=ocTeam.useRateList[data.point.x].offReason[element];
                }
                if (typeof tcTeam.useRateList[data.point.x].offReason[element] != 'undefined' ){
                  bb[element] +=tcTeam.useRateList[data.point.x].offReason[element];
                }
              }

            });
            aa = bb;
          }
        }
        return (
          `<b><i>${tt.useRateList[data.point.x].tooltipPeriod}</i></b>` +
          `<hr size="1" />` +
          `<b>${data.series.name}: ${data.point.y}</b>%` +
          `<hr size="1" />` +
          `전자계약: <b>` + privateMethod.inputNumberFormat(`${tt.useRateList[data.point.x].onlineCount}`) + `건</b><br />` +
          `서면계약: <b>` + privateMethod.inputNumberFormat(`${tt.useRateList[data.point.x].offlineCount}`) + `건</b><br />` +
          `<b>전체계약</b>: <b>` + privateMethod.inputNumberFormat(`${tt.useRateList[data.point.x].totalCount}`) + `건</b>` + privateMethod.offReasonTooltipFormatter(aa)
         );
      },
      tooltipFormatter3(data, area) {
        
        //console.log("area",area)
        let t_area = [];
        if(data.series.name.indexOf("점검") > -1 || data.series.name.indexOf("Part") > -1){
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='OC'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
         
        }else if(data.series.name.indexOf("수리") > -1 || data.series.name.indexOf("Repair") > -1){
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='TC'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
                  
        }else if(data.series.name.indexOf("OSEP") > -1){
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='OS'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
                
        }else if(data.series.name.indexOf("Direct") > -1){
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='TM'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
                
        }else if(data.series.name.indexOf("전자계약2") > -1){
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='OO'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
        }else{
          t_area = area.reduce((a, c) => {
                      if(c.divCd =='TT'){
                        a.push(c);
                      }               
                      return a;
                    }, []);
                    //console.log("점검 토탈");
        }
        
        let aa = (state.searchData.divCd =='SVC_O') ? t_area[data.point.x].offReason : undefined;
       
        return (
          `<b><i>${data.x}</i></b>` +
          `<hr size="1" />` +
          `<b>${data.series.name}: ${data.point.y}</b>%` +
          `<hr size="1" />` +
          `전자계약: <b>` + privateMethod.inputNumberFormat(`${t_area[data.point.x].onlineCount}`) + `건</b><br />` +
          `서면계약: <b>` + privateMethod.inputNumberFormat(`${t_area[data.point.x].offlineCount}`) + `건</b><br />` +
          `<b>전체계약</b>: <b>` + privateMethod.inputNumberFormat(`${t_area[data.point.x].totalCount}`) + `건</b>` + privateMethod.offReasonTooltipFormatter(aa)
        );
      },
      createChart(teamList, page) {
        teamList.forEach(team => {
          const id = privateMethod.getChartId(team, page);          
          createIBChart(id, id, {
            width: '100%',
            height: '300px',
          });
        });
      },
      resizeChart(teamList, page,F) {
        teamList.forEach(team => {
          //console.log("team",team);
          const id = privateMethod.getChartId(team, page);
          const parentElement = document.getElementById(id).parentElement;
          const width = parentElement.offsetWidth - 2;
          const height = width * 0.8;

          window[id].setSize(width, height);
        });

        if(F =='C'){
          const id = 'divChart_TEAM_'+page;
          const parentElement = document.getElementById(id).parentElement;
          const width = parentElement.offsetWidth - 2;
          const height = width * 0.8;
          window[id].setSize(width, height);
        }
        
        if(F == 'S'){
          const id2 = 'divChart_TEAMO_'+page;
          const parentElement2 = document.getElementById(id2).parentElement;
          const width2 = parentElement2.offsetWidth - 2;
          const height2 = width2 * 0.8;
          window[id2].setSize(width2, height2);
        }
      },
      offReasonTooltipFormatter(offReasonArr) {
        let t_table = "";
        //console.log("offReasonArr",offReasonArr);
        if(offReasonArr != undefined){
          t_table = `
                        <hr size="1" />
                        <div style="text-align: center">서면사유</div>
                        <table style="width:250px;border: #435059 1px solid">
                          <thead>
                              <tr >
                                <th style="width:180px;text-align:center;padding:1px;">사유</th>
                                <th style="text-align:center;padding:1px;">건수</th>
                              </tr>
                          </thead>
                          <tbody>
                        `;
          let t_count = 0;
          $.each(state.multiValueOffReason['SVC_O'], function(index, element){
            if(element != undefined){
              if (typeof offReasonArr[element] != 'undefined' && offReasonArr[element] > 0){
                t_table +=`<tr padding:5px;">
                              <td style="text-align:left;padding:1px;border: #435059 1px solid">${element.replace(" _"," ")}</td>
                              <td style="text-align:right;padding:1px;border: #435059 1px solid">${privateMethod.inputNumberFormat(offReasonArr[element])}건</td>
                          </tr>
                        `;             
                t_count += offReasonArr[element];
              }
            }
          });
          t_table +=`<tr padding:5px;>
                              <td style="text-align:center;padding:1px;">합계</td>
                              <td style="text-align:right;padding:1px;">${privateMethod.inputNumberFormat(t_count)}건</td>
                          </tr>`; 
          t_table +='</tbody></table>';
          if(t_count == 0) t_table='';
        }
        //console.log("t_table",t_table);
        return t_table;
      }
    };

    const method = {
      async search_click() {
        
        if(state.searchData.divCd == 'SVC_OE' || state.searchData.divCd =='SVC_TE'){
          if(state.loading == true){
            return false;
          }
          eContractSheetId.removeAll();
          TeCompletionSheetId.removeAll();
          
          await method.getList2();
          return;
        }

        try {
          if(state.dataCall == true){
            state.searchData.period = '';
            state.multiValuePeriod[state.searchData.divCd].splice(0);
            state.loadingMessage = '데이터를 가져오고 있습니다.';
            state.loading = true;
            const response = await axios.post('/statistics/useRateOse', state.searchData);
            state.initData[state.searchData.divCd] = response.data;
          
          
            // contFlag
            let t_contFlag=[];
            state.initData[state.searchData.divCd]
              .reduce((a, c) => {
                if (a.length === 0 || a.indexOf(c.contFlag) === -1) {
                  a.push(c.contFlag);
                }
                return a;
              }, [])
              .sort()
              .forEach(contFlag => {
                t_contFlag.push({text: contFlag, value: contFlag});
              });
            
            let aaa = t_contFlag.reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.value) === -1) {
                a.push(c.value);
              }
              return a;
            }, [])
            .sort();
            const bbb = aaa.slice(-1); //마지막거만 남기기
            if(state.searchData.divCd == 'SVC_O'){
              aaa = bbb;
              console.log("aaa",aaa);
              //delete aaa[0];
              //delete aaa[1];
            }

            if(checkBoxList.contFlag[state.searchData.divCd].length == 0)
            {
              state.multiValueContFlag[state.searchData.divCd].push(...aaa); //체크 목록
              checkBoxList.contFlag[state.searchData.divCd] = t_contFlag;              //체크박스 목록
            }

            //서명이유
            if(state.searchData.divCd == 'SVC_O'){
              let t_offReason=[];
              state.initData[state.searchData.divCd]
                .reduce((a, c) => {
                  if (a.length === 0 || a.indexOf(c.offReason) === -1) {
                    a.push(c.offReason);
                  }
                  return a;
                }, [])
                .sort()
                .forEach(offReason => {
                  t_offReason.push({text: offReason.replace(" _",'\u00A0\u00A0\u00A0\u00A0\u00A0'), value: offReason});
                });
              
                const bbb = t_offReason.reduce((a, c) => {
                  if (a.length === 0 || a.indexOf(c.value) === -1) {
                    a.push(c.value);
                  }
                  return a;
                }, [])
                .sort();
              console.log("bbb[4]",bbb[3]);
              delete bbb[4];
              delete bbb[5];
              delete bbb[6];
              delete bbb[7];
              if(checkBoxList.offReason[state.searchData.divCd].length == 0)
              {
                state.multiValueOffReason[state.searchData.divCd].push(...bbb);
                checkBoxList.offReason[state.searchData.divCd] = t_offReason;              
              }
            }

          }else{
            state.dataCall = true;
          }


          //팀넣기
          teamList=[];
          const useRateList = [];
          if(state.multiValuePeriod[state.searchData.divCd].length > 0){
            useRateList.push(...state.initData[state.searchData.divCd].filter(useRate => state.multiValueDirect[state.searchData.divCd].includes(useRate.direct))
                                              .filter(useRate => state.multiValueContFlag[state.searchData.divCd].includes(useRate.contFlag))
                                              .filter(useRate => (state.searchData.divCd == "SVC_O") ? state.multiValueOffReason[state.searchData.divCd].includes(useRate.offReason) : true)
                                              .filter(useRate => state.multiValuePeriod[state.searchData.divCd].includes(useRate.period)));
          }else{
            useRateList.push(...state.initData[state.searchData.divCd].filter(useRate => state.multiValueDirect[state.searchData.divCd].includes(useRate.direct))
                                              .filter(useRate => (state.searchData.divCd == "SVC_O") ? state.multiValueOffReason[state.searchData.divCd].includes(useRate.offReason) : true)
                                              .filter(useRate => state.multiValueContFlag[state.searchData.divCd].includes(useRate.contFlag)));
          }
          const periodList = [];
          const areaList = [];
          const areaUseRateList = [];

         
          state.loadingMessage = '데이터를 분석하고 있습니다... (1/5)';
          await nextTick();

          // team
          useRateList
            .reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.teamNo.substring(2)) === -1) {
                a.push(c.teamNo.substring(2));
              }

              return a;
            }, [])
            .sort()
            .forEach(teamNo => {
              
              teamList.push({
                divCd: 'OC'
                , pageList: (state.searchData.divCd =='SVC_O') ?  [1] : [2]
                , parentTeamNo: 'OC'
                , parentTeamName: (state.searchData.divCd =='SVC_O') ? '점검계약':'Part'
                , teamNoList: ['O_'+teamNo]
                , teamNo: 'O_'+teamNo
                , teamName: teamNo
              });
              teamList.push({
                divCd: 'TC'
                , pageList: (state.searchData.divCd =='SVC_O') ? [1] : [2]
                , parentTeamNo: 'TC'
                , parentTeamName: (state.searchData.divCd =='SVC_O') ? '수리공사':'Repair'
                , teamNoList: ['T_'+teamNo]
                , teamNo: 'T_'+teamNo
                , teamName: teamNo
              });
              teamList.push({
                divCd: 'TT'
                , pageList: (state.searchData.divCd =='SVC_O') ? [1] : [2]
                , parentTeamNo: 'TT'
                , parentTeamName: (state.searchData.divCd =='SVC_O') ? '점검/수리':'Part/Repair'
                , teamNoList: [teamNo]
                , teamNo: teamNo
                , teamName: teamNo
              });

              areaList.push({
                divCd: 'OC'
                , teamName: teamNo
              });
              areaList.push({
                divCd: 'TC'
                , teamName: teamNo
              });
              areaList.push({
                divCd: 'TT'
                , teamName: teamNo
              });


              teamList.push({
                divCd: 'OS'
                , pageList: (state.searchData.divCd =='SVC_O') ?  [1] : [2]
                , parentTeamNo: 'OS'
                , parentTeamName: 'OSEP'
                , teamNoList: ['O_'+teamNo]
                , teamNo: 'O_'+teamNo
                , teamName: teamNo
              });
              teamList.push({
                divCd: 'TM'
                , pageList: (state.searchData.divCd =='SVC_O') ? [1] : [2]
                , parentTeamNo: 'TM'
                , parentTeamName: 'Direct'
                , teamNoList: ['T_'+teamNo]
                , teamNo: 'T_'+teamNo
                , teamName: teamNo
              });
              teamList.push({
                divCd: 'OO'
                , pageList: (state.searchData.divCd =='SVC_O') ? [1] : [2]
                , parentTeamNo: 'OO'
                , parentTeamName: 'Direct/OSEP'
                , teamNoList: [teamNo]
                , teamNo: teamNo
                , teamName: teamNo
              });

              areaList.push({
                divCd: 'OS'
                , teamName: teamNo
              });
              areaList.push({
                divCd: 'TM'
                , teamName: teamNo
              });
              areaList.push({
                divCd: 'OO'
                , teamName: teamNo
              });
              
            });

          method.page1TeamListSet();
          
          // period
          let t_preiod=[];
          useRateList
            .reduce((a, c) => {
              if (a.length === 0 || a.indexOf(c.period) === -1) {
                a.push(c.period);
              }

              return a;
            }, [])
            .sort()
            .forEach(period => {
              let displayPeriod = `${period.substring(2, 4)}P${period.substring(4, 6)}`;
              const tooltipPeriod = displayPeriod;
              if (period.substring(4, 6) !== '01') {
                displayPeriod = `P${period.substring(4, 6)}`;
              }

              periodList.push({
                period: period,
                displayPeriod: displayPeriod,
                tooltipPeriod: tooltipPeriod,
                useRate: undefined,
              });

              t_preiod.push({text: period, value: period});
            });

          if (periodList.length > 0) {
            periodList[0].displayPeriod = `${periodList[0].period.substring(2, 4)}P${periodList[0].period.substring(
              4,
              6
            )}`;
          }

          if(checkBoxList.period[state.searchData.divCd].length == 0)
          {
            checkBoxList.period[state.searchData.divCd] = t_preiod;
          }

          
          state.loadingMessage = '데이터를 분석하고 있습니다... (2/5)';
          await nextTick();

          // 기간/팀 별
          teamList.forEach(team => {
            team.useRateList = [];
            
            periodList.forEach(period => {
              const useRateListByPeriodAndTeam = useRateList.filter(
                useRate => (team.divCd === 'TT' || team.divCd === 'OO') ? ((team.divCd === 'TT') ? (useRate.divCd ==='OC' || useRate.divCd ==='TC' ) &&  team.teamNoList.includes(useRate.teamNo.substring(2)) && useRate.period === period.period.toString() 
                                                                                                  : (useRate.divCd ==='OS' || useRate.divCd ==='TM' ) && team.teamNoList.includes(useRate.teamNo.substring(2)) && useRate.period === period.period.toString()  )            
                                                                          : team.divCd == useRate.divCd  && team.teamNoList.includes(useRate.teamNo) && useRate.period === period.period.toString()
              );
              
              useRateTemplate.tabCf[state.searchData.divCd].offReason = [];
              const useRate = useRateListByPeriodAndTeam.reduce(
                (a, c) => {
                  
                  const t_divCd = (c.divCd ==='TC' || c.divCd ==='OC') ?  c.divCd : (c.divCd ==='OS') ? 'OC' : 'TC'
                  a.onlineCount += c[`${t_divCd.toLowerCase()}ContractOnlineCount`];
                  a.offlineCount += c[`${t_divCd.toLowerCase()}ContractOfflineCount`];
                                    
                  if(team.divCd == c.divCd && team.teamNo == c.teamNo && period.period == c.period){
                    if(typeof a.offReason[c.offReason.toString()] == 'undefined'){
                      a.offReason[c.offReason]=0;
                    }
                    a.offReason[c.offReason.toString()] += (c[`${t_divCd.toLowerCase()}ContractOnlineCount`]+c[`${t_divCd.toLowerCase()}ContractOfflineCount`]);
                  } 
                              
                  return a;
                },
                {
                  ...useRateTemplate.tabCf[state.searchData.divCd],
                  period: period.period.toString(),
                  displayPeriod: period.displayPeriod,
                  tooltipPeriod: period.tooltipPeriod,
                }
              );

              privateMethod.calcurateUseRate(useRate);

              team.useRateList.push(useRate);
            });
          });

          

          //본부별
          areaList.forEach(area => {
            const useRateListByPeriodAndTeam = useRateList.filter(
              useRate => (area.divCd === 'TT' || area.divCd === 'OO') ? ( (area.divCd === 'TT') ? (useRate.divCd ==='OC' || useRate.divCd ==='TC' ) && area.teamName.includes(useRate.teamNo.substring(2)) : (useRate.divCd ==='OS' || useRate.divCd ==='TM' ) && area.teamName.includes(useRate.teamNo.substring(2)) )
                                                                            : area.divCd == useRate.divCd  && area.teamName.includes(useRate.teamNo.substring(2)) && useRate.divCd === area.divCd.toString()
            );

            useRateTemplate.tabCf[state.searchData.divCd].offReason = [];
            const useRate = useRateListByPeriodAndTeam.reduce(
              (a, c) => {
                const t_divCd = (c.divCd ==='TC' || c.divCd ==='OC') ?  c.divCd : (c.divCd ==='OS') ? 'OC' : 'TC'
                a.onlineCount += c[`${t_divCd.toLowerCase()}ContractOnlineCount`];
                a.offlineCount += c[`${t_divCd.toLowerCase()}ContractOfflineCount`];
                
                if(area.divCd == c.divCd && area.teamName == c.teamNo.substring(2)){
                  if(typeof a.offReason[c.offReason.toString()] == 'undefined'){
                    a.offReason[c.offReason]=0;
                  }
                  a.offReason[c.offReason.toString()] += (c[`${t_divCd.toLowerCase()}ContractOnlineCount`]+c[`${t_divCd.toLowerCase()}ContractOfflineCount`]);
                }else if((area.divCd === 'TT' || area.divCd === 'OO') && area.teamName == c.teamNo.substring(2)){                  
                  
                    if(typeof a.offReason[c.offReason.toString()] == 'undefined'){
                      a.offReason[c.offReason]=0;
                    }
                    a.offReason[c.offReason.toString()] += (c[`tcContractOnlineCount`]+c[`tcContractOfflineCount`]+c[`ocContractOnlineCount`]+c[`ocContractOfflineCount`]);
                }
               

                return a;
              },
              {
                ...useRateTemplate.tabCf[state.searchData.divCd],
                divCd: area.divCd.toString(),
                displayArea: area.teamName,
                tooltipArea: area.teamName,
              }
            );

            privateMethod.calcurateUseRate(useRate);

            areaUseRateList.push(useRate);
          });
         
          //본부별

          state.loadingMessage = '데이터를 분석하고 있습니다... (3/5)';
          await nextTick();

          // 기간/상위 팀 별
          //console.log("useRateTemplate.tabCf[state.searchData.divCd]",useRateTemplate.tabCf[state.searchData.divCd]);
          parentTeamList.forEach(parentTeam => {
            parentTeam.useRateList = [];
            //console.log("parentTeamList",useRateTemplate.tabCf[state.searchData.divCd]);
            periodList.forEach(period => {
              parentTeam.useRateList.push({
                ...useRateTemplate.tabCf[state.searchData.divCd],
                period: period.period.toString(),
                displayPeriod: period.displayPeriod,
                tooltipPeriod: period.tooltipPeriod,
              });
            });
            
            const teamListByParentTeam = teamList.filter(team => team.parentTeamNo === parentTeam.teamNo);
            teamListByParentTeam.forEach(team => {
              periodList.forEach(period => {
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const t = team.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                parentTeam.useRateList[p].onlineCount += team.useRateList[t].onlineCount;
                parentTeam.useRateList[p].offlineCount += team.useRateList[t].offlineCount;

                privateMethod.calcurateUseRate(parentTeam.useRateList[p]);
              });
            });
          });

          state.loadingMessage = '데이터를 분석하고 있습니다... (4/5)';
          await nextTick();

          // 사업부 별
          //console.log("useRateTemplate.tabCf[state.searchData.divCd]",useRateTemplate.tabCf[state.searchData.divCd]);
          //console.log("state.searchData.divCd",state.searchData.divCd);
          divList.forEach(div => {
            if(state.searchData.divCd == 'SVC_O' && div.pageList.includes(2)) {
              return;
            }
            if(state.searchData.divCd == 'SVC_T' && div.pageList.includes(1)) {
              return;
            }
            div.useRateList = [];
            
            periodList.forEach(period => {
              const useRateListByPeriodAndTeam = useRateList.filter(
                useRate => (div.divCd === 'TT' || div.divCd === 'OO') ? ((div.divCd === 'TT') ? (useRate.divCd ==='OC' || useRate.divCd ==='TC' ) && useRate.period === period.period.toString() 
                                                                                                  : (useRate.divCd ==='OS' || useRate.divCd ==='TM' ) && useRate.period === period.period.toString()  )            
                                                                          : div.divCd == useRate.divCd && useRate.period === period.period.toString()
              );


              //console.log("useRateListByPeriodAndTeam",useRateListByPeriodAndTeam);
              useRateTemplate.tabCf[state.searchData.divCd].offReason = [];
                // div.useRateList.push({
                //   ...useRateTemplate.tabCf[state.searchData.divCd],
                //   period: period.period.toString(),
                //   displayPeriod: period.displayPeriod,
                //   tooltipPeriod: period.tooltipPeriod,
                // });
              // if(state.searchData.divCd =='SVC_O'){ //서면사유가 필요함
                useRateTemplate.tabCf[state.searchData.divCd].offReason = [];
                const useRate = useRateListByPeriodAndTeam.reduce(
                  (a, c) => {
                    //a = useRateTemplate.tabCf[state.searchData.divCd];
                    const t_divCd = (c.divCd ==='TC' || c.divCd ==='OC') ?  c.divCd : (c.divCd ==='OS') ? 'OC' : 'TC'
                    
                    if(div.divCd == c.divCd && period.period == c.period){
                      if(typeof a.offReason[c.offReason.toString()] == 'undefined'){
                        a.offReason[c.offReason]=0;
                      }
                      a.offReason[c.offReason.toString()] += (c[`${t_divCd.toLowerCase()}ContractOnlineCount`]+c[`${t_divCd.toLowerCase()}ContractOfflineCount`]);
                    } 
                                
                    return a;
                  },
                  {
                    ...useRateTemplate.tabCf[state.searchData.divCd],
                    period: period.period.toString(),
                    displayPeriod: period.displayPeriod,
                    tooltipPeriod: period.tooltipPeriod,
                  }
                );
                div.useRateList.push(useRate);
              // }else{
              //   div.useRateList.push({
              //     ...useRateTemplate.tabCf[state.searchData.divCd],
              //     period: period.period.toString(),
              //     displayPeriod: period.displayPeriod,
              //     tooltipPeriod: period.tooltipPeriod,
              //   });
              // }
              
            });
            console.log("divList",divList);
            

            const parentTeamByDiv = parentTeamList.filter(parentTeam => parentTeam.divCd === div.divCd);

            parentTeamByDiv.forEach(parentTeam => {
              periodList.forEach(period => {
                const d = div.useRateList.findIndex(useRate => useRate.period === period.period.toString());
                const p = parentTeam.useRateList.findIndex(useRate => useRate.period === period.period.toString());

                div.useRateList[d].onlineCount += parentTeam.useRateList[p].onlineCount;
                div.useRateList[d].offlineCount += parentTeam.useRateList[p].offlineCount;
                
                privateMethod.calcurateUseRate(div.useRateList[d]);
              });
            });
          });

          
          state.periodList = periodList;
          state.areaList = areaList;

          state.loadingMessage = '데이터를 분석하고 있습니다... (5/5)';
          await nextTick();
          console.log("state.searchData.divCd",state.searchData.divCd);
          console.log("state.ocPage1TeamList[state.searchData.divCd]",state.ocPage1TeamList[state.searchData.divCd]);
          state.ocPage1TeamList[state.searchData.divCd].forEach((team,index) => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
            
            const tcData =  state.tcPage1TeamList[state.searchData.divCd][index].useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
            const totalData = state.totalPage1TeamList[state.searchData.divCd][index].useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
           
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: (index ===0) ? ((state.searchData.divCd =='SVC_O') ? 'OSE(전체)':'OSE(전체)' ):`${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },                  
                },
                legend: {//범례
                  enabled: false ,// (index ===0) ? true : false,
                  
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter2(this, team, state.tcPage1TeamList[state.searchData.divCd][index],state.totalPage1TeamList[state.searchData.divCd][index]);
                  },
                },
                series: [                  
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: (state.searchData.divCd =='SVC_O') ? '#12239e' :'#d9b300' ,
                    name: (state.searchData.divCd =='SVC_O') ? '수리 전자계약 활용율' :'Repair 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: tcData,
                    id: 'someId',
                  },
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: (state.searchData.divCd =='SVC_O') ? '#d9b300' :'#12239e' , 
                    name: (state.searchData.divCd =='SVC_O') ? '점검 전자계약 활용율':'Part 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                    id: 'someId2',
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: totalData,
                    id: 'someId3',
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });
          /*지역합계 */
            window[`divChart_TEAM_${state.searchData.divCd}`].removeAll();
            window[`divChart_TEAM_${state.searchData.divCd}`].setOptions(
              {
                
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: '지사별',
                },
                chart: {
                   borderWidth: 0,
                   type: 'column',
                },
                plotOptions: {
                  // series: {
                  //     //depth: 25,
                  //     colorByPoint: true
                  // },
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: areaList.reduce((a, c) => {
                              if(c.divCd =='TT'){
                                a.push(c.teamName);
                              }                              
                              return a;
                            }, []),
                  labels: {
                            autoRotation: [-45, -90],
                           
                        },
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter3(this,areaUseRateList);
                  },
                },
                series: [                  
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: (state.searchData.divCd =='SVC_O') ? '#12239e' : '#d9b300',
                    name: (state.searchData.divCd =='SVC_O') ? '수리 전자계약 활용율':'Repair 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data:  areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='TC'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                            //showInLegend: false
                  },
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color:  (state.searchData.divCd =='SVC_O') ? '#d9b300' : '#12239e', 
                    name: (state.searchData.divCd =='SVC_O') ? '점검 전자계약 활용율' : 'Part 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='OC'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                            //showInLegend: false
                  },
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='TT'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          /*지역합계 */
          

          state.osPage1TeamList[state.searchData.divCd].forEach((team,index) => {
            const data = team.useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
            
            const tmData =  state.tmPage1TeamList[state.searchData.divCd][index].useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
            const totalmData = state.totalmPage1TeamList[state.searchData.divCd][index].useRateList.reduce((a, c) => {
              a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
              return a;
            }, []);
            
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].removeAll();
            window[`divChart_${team.divCd}_1_${team.teamNo}_${state.searchData.divCd}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: (index ===0) ? 'Direct/OSEP':`${team.teamName}`,
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                  },
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: periodList.map(period => period.displayPeriod),
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter2(this, team, state.tmPage1TeamList[state.searchData.divCd][index],state.totalmPage1TeamList[state.searchData.divCd][index]);
                  },
                },
                series: [        
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: '#12239e', 
                    name: 'OSEP 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: data,
                  },          
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: '#d9b300',
                    name: 'Direct 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: tmData,
                  },
                  
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약2 활용율',
                    stack: '전자계약 활용율',
                    data: totalmData,
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
          });
          /*지역합계 */
            window[`divChart_TEAMO_${state.searchData.divCd}`].removeAll();
            window[`divChart_TEAMO_${state.searchData.divCd}`].setOptions(
              {
                title: {
                  style: {
                    fontSize: '22px',
                  },
                  text: '지사별',
                },
                chart: {
                  borderWidth: 0,
                  type: 'column',
                },
                plotOptions: {
                  // series: {
                  //     //depth: 25,
                  //     colorByPoint: true
                  // },
                  column: {
                    stacking: 'normal',
                  },  
                },
                legend: {
                  enabled: false,
                },
                xAxis: {
                  ...CHART.XAXIS,
                  categories: areaList.reduce((a, c) => {
                              if(c.divCd =='OO'){
                                a.push(c.teamName);
                              }                              
                              return a;
                            }, []),
                  labels: {
                            autoRotation: [-45, -90],
                            
                          },
                },
                yAxis: [
                  {
                    ...CHART.YAXIS_COLUMN,
                  },
                  {
                    ...CHART.YAXIS_LINE,
                  },
                ],
                tooltip: {
                  useHTML: true,
                  formatter: function() {
                    return privateMethod.tooltipFormatter3(this,areaUseRateList);
                  },
                },
                series: [                  
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: '#12239e', 
                    name: 'OSEP 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data: areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='OS'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                            showInLegend: false
                  },
                  {
                    ...{
                      ...CHART.SERIES_COLUMN,
                    },
                    color: '#d9b300',
                    name: 'Direct 전자계약 활용율',
                    stack: '전자계약 활용율',
                    data:  areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='TM'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                            showInLegend: false
                  },
                  
                  {
                    ...CHART.SERIES_LINE,
                    name: '전자계약2 활용율',
                    stack: '전자계약 활용율',
                    data: areaUseRateList.reduce((a, c) => {
                              if(c.divCd =='OO'){
                                a.push(isNaN(c.onlineRate) ? 0 : c.onlineRate);
                              }                              
                              return a;
                            }, []),
                  },
                ],
              },
              {
                append: true,
                redraw: true,
              }
            );
        } catch (ex) {
          console.log(ex);
        } finally {
          state.loading = false;
          state.loadingMessage = undefined;
        }
        //전체 높이 맞추기
        $("#contents .scroll_wrap").css("height","calc(100vh - 20px)");

        //지사별을 2번째칸으로
        $('#divChart_TEAM_SVC_O').closest("div").parent("div").before($('#divChart_OC_1_OC_SVC_O').closest("div").parent("div"));
        $('#divChart_TEAMO_SVC_O').closest("div").parent("div").before($('#divChart_OS_1_OS_SVC_O').closest("div").parent("div"));

        $('#divChart_TEAM_SVC_T').closest("div").parent("div").before($('#divChart_OC_1_OC_SVC_T').closest("div").parent("div"));
        $('#divChart_TEAMO_SVC_T').closest("div").parent("div").before($('#divChart_OS_1_OS_SVC_T').closest("div").parent("div"));
      },

      async ocPage1ColumnSize_change(e) {
        await nextTick();

        privateMethod.resizeChart(state.ocPage1TeamList[state.searchData.divCd], state.searchData.divCd,'C');        
        //privateMethod.resizeChart(state.osPage1TeamList['SVC_O'], 'SVC_O');
      },

      async osPage1ColumnSize_change(e) {
        await nextTick();        
        privateMethod.resizeChart(state.osPage1TeamList[state.searchData.divCd], state.searchData.divCd,'S');
        //privateMethod.resizeChart(state.osPage1TeamList['SVC_T'], 'SVC_T');
      },

      async page1TeamListSet() {
        var n = (state.searchData.divCd == 'SVC_O') ? 1:2;
        state.ocPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div => div.divCd === 'OC' && div.pageList.includes(n)),
          ...teamList.filter(team => team.divCd === 'OC' && team.pageList.includes(n)),
        ];
        state.ocTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'OC' && team.pageList.includes(n))
        ];
        state.tcTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'TC' && team.pageList.includes(n))
        ];
        state.tcPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div => div.divCd === 'TC' && div.pageList.includes(n)),
          ...teamList.filter(team => team.divCd === 'TC' && team.pageList.includes(n)),
        ];
        state.totalTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'TT' && team.pageList.includes(n))
        ];
        state.totalPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div => div.divCd === 'TT' && div.pageList.includes(n)),
          ...teamList.filter(team => team.divCd === 'TT' && team.pageList.includes(n)),
        ];

        state.osPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div => div.divCd === 'OS' && div.pageList.includes(n)),
          ...teamList.filter(team => team.divCd === 'OS' && team.pageList.includes(n)),
        ];
        state.osTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'OS' && team.pageList.includes(n))
        ];
        state.tmTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'TM' && team.pageList.includes(n))
        ];
        state.tmPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div => div.divCd === 'TM' && div.pageList.includes(n)),
          ...teamList.filter(team => team.divCd === 'TM' && team.pageList.includes(n)),
        ];
        state.totalmTeamList[state.searchData.divCd] = [
          ...teamList.filter(team => team.divCd === 'OO' && team.pageList.includes(n))
        ];
        state.totalmPage1TeamList[state.searchData.divCd] = [
          ...divList.filter(div =>  div.divCd === 'OO' && div.pageList.includes(n)),
          ...teamList.filter(team =>  team.divCd === 'OO' && team.pageList.includes(n)),
        ];

        await nextTick();
        privateMethod.createChart(state.ocPage1TeamList['SVC_O'], 'SVC_O');
        privateMethod.createChart(state.osPage1TeamList['SVC_O'], 'SVC_O');        
        
        privateMethod.createChart(state.ocPage1TeamList['SVC_T'], 'SVC_T');
        privateMethod.createChart(state.osPage1TeamList['SVC_T'], 'SVC_T');
        
        //console.log("state.ocPage1TeamList",state.ocPage1TeamList);
        createIBChart('divChart_TEAM_SVC_O', "divChart_TEAM_SVC_O", {
            width: '100%',
            height: '300px',
        });

        createIBChart('divChart_TEAMO_SVC_O', "divChart_TEAMO_SVC_O", {
            width: '100%',
            height: '300px',
        });

        createIBChart('divChart_TEAM_SVC_T', "divChart_TEAM_SVC_T", {
            width: '100%',
            height: '300px',
        });

        createIBChart('divChart_TEAMO_SVC_T', "divChart_TEAMO_SVC_T", {
            width: '100%',
            height: '300px',
        });
      },


      //Download
      async excel_click(e) {
        var param = {};
        if(e == 'SVC_OE'){
          param = {
              fileName:`eContract_${state.searchData.fromDate}_.${state.searchData.toDate}.xlsx`,
          };
          eContractSheetId.down2Excel(param);
        }else if(e == 'SVC_TE'){
          param = {
              fileName:`T eCompletion_${state.searchData.fromDate}_.${state.searchData.toDate}.xlsx`,
          };
          TeCompletionSheetId.down2Excel(param);
        }
      },
      async gridCreated() {
        const ibsheetLoader = window.IBSheetLoader;
        await ibsheetLoader.createSheet({
            id: "eContractSheetId",        // 시트 객체 ID
            el: "eContractSheetDiv",     // 시트를 생성할 DIV객체 ID
            options: ibsheetOptions1,       // 초기화 구문 변수
            data: [] 
        });

        await ibsheetLoader.createSheet({
            id: "TeCompletionSheetId",        // 시트 객체 ID
            el: "TeCompletionSheetDiv",     // 시트를 생성할 DIV객체 ID
            options: ibsheetOptions2,       // 초기화 구문 변수
            data: [] 
        });
      },
      async getList2() {
        if (state.loading) {
          return;
        }
      
        state.loadingMessage = '데이터를 가져오고 있습니다. 1/4';
        state.loading = true;
       
        try {
          const response = await axios.post('/statistics/useRateOse', state.searchData);
          
          
          state.loading = false;
          state.loadingMessage = '데이터를 가져오고 있습니다. 2/4';
          state.loading = true;
          state.list1 = response.data;
          await nextTick();
          eContractSheetId.loadSearchData( response.data, 1 );
          
        } catch (ex) {
          state.loading = false;
          console.log(ex);
        } finally {
          //state.loading = false;
        }
        
      },
    };

    
    watch(
      state.multiValueDirect,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        if(state.multiValueDirect[state.searchData.divCd].length == 0){
          await nextTick();
          if(state.searchData.direct == 'TM'){
            state.multiValueDirect[state.searchData.divCd].push('OS');
          }else{
            state.multiValueDirect[state.searchData.divCd].push('TM');
          }          
        }else{
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);
        }
        state.searchData.direct = state.multiValueDirect[state.searchData.divCd].join();
      },
      //{deep :true}
    );

    watch(
      state.multiValueContFlag,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        if(state.searchData.contFlag == undefined){
          state.searchData.contFlag = state.multiValueContFlag[state.searchData.divCd].join();
        }
        if(state.multiValueContFlag[state.searchData.divCd].length == 0){
          await nextTick();
          if(state.searchData.contFlag == '비표준'){
            state.multiValueContFlag[state.searchData.divCd].push('비표준효율화','표준');
          }else if(state.searchData.contFlag == '비표준효율화'){
            state.multiValueContFlag[state.searchData.divCd].push('비표준','표준');
          }else if(state.searchData.contFlag == '표준'){
            state.multiValueContFlag[state.searchData.divCd].push('비표준','비표준효율화');
          }else if(state.searchData.contFlag == '유상'){
            state.multiValueContFlag[state.searchData.divCd].push('무상','CLAIM');
          }else if(state.searchData.contFlag == '무상'){
            state.multiValueContFlag[state.searchData.divCd].push('유상','CLAIM');
          }else if(state.searchData.contFlag == 'CLAIM'){
            state.multiValueContFlag[state.searchData.divCd].push('무상','유상');
          }         
        }else{
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);
        }
        state.searchData.contFlag = state.multiValueContFlag[state.searchData.divCd].join();
      }
    );

    watch(
      state.multiValuePeriod,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        state.searchData.period = state.multiValuePeriod[state.searchData.divCd].join();
        //if(state.searchData.period !=''){
          state.dataCall = false;
          state.loadingMessage = '데이터를 필터링중 입니다.';
          state.loading = true;
          setTimeout(function(){          
            method.search_click();
          },100);         
        //}        
      }
    );

    watch(
      state.searchData,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
        
        if(ov.divCd == 'SVC_T' && checkBoxList.contFlag['SVC_T'].length == 0){
          setTimeout(function(){          
            method.search_click();
          },100);
        }

        if(ov.divCd == 'SVC_OE' && state.list1 == undefined){
          setTimeout(function(){       
            state.searchData.period = '';   
            method.search_click();
          },100);
        }
        
      }
    );

    watch(
      state.multiValueOffReason,
      async (nv, ov) => {
        if(state.loading == true){
          return false;
        }
      
        state.dataCall = false;
        state.loadingMessage = '데이터를 필터링중 입니다.';
        state.loading = true;
        setTimeout(function(){          
          method.search_click();
        },100);
      }
    );

    onMounted(async () => {
      $(".heading_area .btitle").html("(SVC)전자계약 활용율");
      $(".heading_area .breadcrumbs").html('<span class="home fc_bl01">SVC</span><span>기타 조회</span><span>(SVC)전자계약 활용율</span>');
      $(".heading_area").hide(); //2개 나와서 이건 숨김

      await nextTick();
      try{
        const ibsheetLoader = window.IBSheetLoader;
        await ibsheetLoader.removeSheet("eContractSheetId");
        await ibsheetLoader.removeSheet("TeCompletionSheetId");
      }catch(e){
        console.log(e);
      }
      await method.gridCreated();

      method.search_click();

      
    });

    return {
      checkBoxList,
      state,
      ...method,
    };
  },
});
</script>
<template>
<article id="contents">
  <otis-page :loading="state.loading" :loading-message="state.loadingMessage" @search-click="search_click" @excel-click="excel_click">
    <iui-tab-group>
      <template #title-left>
        <iui-button 
            :color="state.searchData.divCd == 'SVC_O' ? 'blue big' : 'gray'"
             
            @click="state.searchData.divCd = 'SVC_O'">SVC eContract</iui-button>
        <iui-button
            :color="state.searchData.divCd == 'SVC_T' ? 'blue big' : 'gray'"
            
            @click="state.searchData.divCd = 'SVC_T'">SVC T eCompletion</iui-button>
        <iui-button
            :color="(state.searchData.divCd == 'SVC_OE' || state.searchData.divCd == 'SVC_TE' ) ? 'blue big' : 'gray'"
            
            @click="state.searchData.divCd = 'SVC_OE'">Download</iui-button>
      </template>
    
    
      <iui-container type="css-flex" theme="bullet" v-show="state.searchData.divCd == 'SVC_O'">
        <i-row height="100px" ref="searchBox">
          <i-col>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col width="120px">
                  <iui-checkbox-group v-model="state.multiValueDirect['SVC_O']" :items="checkBoxList.direct" item-direction="column" />
                </i-col>
                <i-col-header width="40px">|</i-col-header>
                <i-col >
                  <iui-checkbox-group v-model="state.multiValueContFlag['SVC_O']" :items="checkBoxList.contFlag['SVC_O']" item-direction="column" />
                </i-col>
                <!-- <i-col-header width="40px">|</i-col-header>
                <i-col>
                  <iui-checkbox-group v-model="state.multiValuePeriod['SVC_O']" :items="checkBoxList.period['SVC_O']" item-direction="column" />
                </i-col> -->
              </i-row>
              <i-row>            
                <i-col width="120px">
                  <span style="font-weight: bold;">OSE Period</span>
                </i-col>
                <i-col-header width="40px">|</i-col-header>  
                <i-col>               
                  <iui-checkbox-group v-model="state.multiValuePeriod['SVC_O']" :items="checkBoxList.period['SVC_O']" item-direction="column" />
                </i-col>
              </i-row>
              <i-row>            
                <i-col width="120px">
                  <span style="font-weight: bold;">서면사유</span>
                </i-col>
                <i-col-header width="40px">|</i-col-header>  
                <i-col>               
                  <iui-checkbox-group v-model="state.multiValueOffReason['SVC_O']" :items="checkBoxList.offReason['SVC_O']" item-direction="column" />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <iui-tab-group>
              <iui-tab title="점검/수리 통합">
                <iui-content-box>
                  <template #title-left>
                    <i-col-header><span style="margin-right: 20px; margin-left: 30px;"><span style="color:#d9b300">■</span>{{state.tabTitle[0]}}</span><span style="color:#12239e">■</span>{{state.tabTitle[1]}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i-col-header>
                  </template>
                  <template #title-right>
                    <iui-select
                      v-model="state.ocPage1ColumnSize['SVC_O']"
                      :items="state.codeList.columnSizeList"
                      item-key-alias="key"
                      item-text-alias="value"
                      item-value-alias="key"
                      label=" 한 줄 표시:"
                      @change="ocPage1ColumnSize_change"
                    />
                  </template>
                  <iui-container type="css-flex" no-border>
                    <i-row style="flex-wrap: wrap;">
                      <i-col key="team" :width="state.ocPage1ColumnWidth">
                        <div id="divChart_TEAM_SVC_O"></div>
                      </i-col>
                      <i-col v-for="team in state.ocPage1TeamList['SVC_O']" :key="team.teamNo" :width="state.ocPage1ColumnWidth">
                        <div :id="`divChart_${team.divCd}_1_${team.teamNo}_SVC_O`"></div>
                      </i-col>                    
                    </i-row>
                  </iui-container>
                </iui-content-box>
              </iui-tab>
              
              <iui-tab title="Direct/OSEP통합">
                <iui-content-box>
                  <template #title-left>
                    <i-col-header><span style="margin-right: 20px; margin-left: 30px;"><span style="color:#d9b300">■</span>Direct</span><span style="color:#12239e">■</span>OSEP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i-col-header>
                  </template>
                  
                  <template #title-right>
                    <iui-select
                      v-model="state.osPage1ColumnSize['SVC_O']"
                      :items="state.codeList.columnSizeList"
                      item-key-alias="key"
                      item-text-alias="value"
                      item-value-alias="key"
                      label="한 줄 표시:"
                      @change="osPage1ColumnSize_change"
                    />
                  </template>
                  <iui-container type="css-flex">
                    <i-row style="flex-wrap: wrap;">
                      <i-col key="teamo" :width="state.osPage1ColumnWidth">
                        <div id="divChart_TEAMO_SVC_O"></div>
                      </i-col>
                      <i-col v-for="team in state.osPage1TeamList['SVC_O']" :key="team.teamNo" :width="state.osPage1ColumnWidth">
                        <div :id="`divChart_${team.divCd}_1_${team.teamNo}_SVC_O`" style="width: 100%; height: 300px;"></div>
                      </i-col>
                    </i-row>
                  </iui-container>
                </iui-content-box>
              </iui-tab>
            </iui-tab-group>
          </i-col>
        </i-row>
      </iui-container>

      


      <iui-container type="css-flex" theme="bullet" v-show="state.searchData.divCd == 'SVC_T'">
        <i-row height="70px" ref="searchBox">
          <i-col>
            <iui-container type="table" theme="bullet">
              <i-row>
                <i-col width="120px">
                  <iui-checkbox-group v-model="state.multiValueDirect['SVC_T']" :items="checkBoxList.direct" item-direction="column" />
                </i-col>
                <i-col-header width="40px">|</i-col-header>
                <i-col >
                  <iui-checkbox-group v-model="state.multiValueContFlag['SVC_T']" :items="checkBoxList.contFlag['SVC_T']" item-direction="column" />
                </i-col>
                <!-- <i-col-header width="40px">|</i-col-header>
                <i-col>
                  <iui-checkbox-group v-model="state.multiValuePeriod['SVC_T']" :items="checkBoxList.period['SVC_T']" item-direction="column" />
                </i-col> -->
              </i-row>
              <i-row>            
                <i-col width="120px">
                  <span style="font-weight: bold;">OSE Period</span>
                </i-col>
                <i-col-header width="40px">|</i-col-header>  
                <i-col>               
                  <iui-checkbox-group v-model="state.multiValuePeriod['SVC_T']" :items="checkBoxList.period['SVC_T']" item-direction="column" />
                </i-col>
              </i-row>
            </iui-container>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <iui-tab-group>
              <iui-tab title="Part/Repair 통합">
                <iui-content-box>
                  <template #title-left>
                    <i-col-header><span style="margin-right: 20px; margin-left: 30px;"><span style="color:#d9b300">■</span>{{state.tabTitle[1]}}</span><span style="color:#12239e">■</span>{{state.tabTitle[0]}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i-col-header>
                    
                  </template>
                  <template #title-right>
                    <iui-select
                      v-model="state.ocPage1ColumnSize['SVC_T']"
                      :items="state.codeList.columnSizeList"
                      item-key-alias="key"
                      item-text-alias="value"
                      item-value-alias="key"
                      label=" 한 줄 표시:"
                      @change="ocPage1ColumnSize_change"
                    />
                  </template>
                  <iui-container type="css-flex" no-border>
                    <i-row style="flex-wrap: wrap;">
                      <i-col key="team" :width="state.ocPage1ColumnWidth">
                        <div id="divChart_TEAM_SVC_T"></div>
                      </i-col>
                      <i-col v-for="team in state.ocPage1TeamList['SVC_T']" :key="team.teamNo" :width="state.ocPage1ColumnWidth">
                        <div :id="`divChart_${team.divCd}_1_${team.teamNo}_SVC_T`"></div>
                      </i-col>                    
                    </i-row>
                  </iui-container>
                </iui-content-box>
              </iui-tab>
              
              <iui-tab title="Direct/OSEP통합">
                <iui-content-box>
                  <template #title-left>
                    <i-col-header><span style="margin-right: 20px; margin-left: 30px;"><span style="color:#d9b300">■</span>Direct</span><span style="color:#12239e">■</span>OSEP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i-col-header>
                    
                  </template>
                  <template #title-right>
                    <iui-select
                      v-model="state.osPage1ColumnSize['SVC_T']"
                      :items="state.codeList.columnSizeList"
                      item-key-alias="key"
                      item-text-alias="value"
                      item-value-alias="key"
                      label="한 줄 표시:"
                      @change="osPage1ColumnSize_change"
                    />
                  </template>
                  <iui-container type="css-flex">
                    <i-row style="flex-wrap: wrap;">
                      <i-col key="teamo" :width="state.osPage1ColumnWidth">
                        <div id="divChart_TEAMO_SVC_T"></div>
                      </i-col>
                      <i-col v-for="team in state.osPage1TeamList['SVC_T']" :key="team.teamNo" :width="state.osPage1ColumnWidth">
                        <div :id="`divChart_${team.divCd}_1_${team.teamNo}_SVC_T`" style="width: 100%; height: 300px;"></div>
                      </i-col>
                    </i-row>
                  </iui-container>
                </iui-content-box>
              </iui-tab>
            </iui-tab-group>
          </i-col>
        </i-row>
      </iui-container>


      <iui-container type="css-flex" theme="bullet" v-show="(state.searchData.divCd == 'SVC_OE' || state.searchData.divCd == 'SVC_TE')">
        <i-row height="10px" ref="searchBox">
          <i-col>
            <iui-container type="table" theme="bullet">
              
            </iui-container>
          </i-col>
        </i-row>
        <i-row>
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                eContract Download
                <iui-button              
                   @click="excel_click('SVC_OE')">엑셀다운</iui-button>
                &nbsp; {{state.searchData.fromDate}} ~ {{state.searchData.toDate}}
              </template>
              <!-- <iui-container
                type="ibsheet"
                
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.list1"
              /> -->
              <!--데이타가 많을경우  vue 에서 메모리 스택오류발생으로 vue 사용안하고 하드코등으로 -->
              <div id="eContractSheetDiv" style="width:100%;">aaaaaaa</div>
            </iui-content-box>
          </i-col>
          <i-spacer />
          <i-col>
            <iui-content-box no-border-box>
              <template #title>
                T eCompletion Donwload
                <iui-button              
                   @click="excel_click('SVC_TE')">엑셀다운</iui-button>
                &nbsp; {{state.searchData.fromDate}} ~ {{state.searchData.toDate}}
              </template>
              <!-- <iui-container
                type="ibsheet"
                
                :ibsheet.sync="state.grid2"
                :ibsheet-options="state.gridOptions2"
                :ibsheet-data="state.list2"
              /> -->
              <!--데이타가 많을경우  vue 에서 메모리 스택오류발생으로 vue 사용안하고 하드코등으로 -->
              <div id="TeCompletionSheetDiv" style="width:100%;">aaaaaaa</div>
            </iui-content-box>
          </i-col>
        </i-row>



        <!-- <i-row>
          
          <i-col>
            <iui-content-box>
              <iui-container
                type="ibsheet"
                
                :ibsheet.sync="state.grid1"
                :ibsheet-options="state.gridOptions1"
                :ibsheet-data="state.list1"
              
              />
            </iui-content-box>
          </i-col>
          <i-col>
            <iui-content-box>
              <iui-container
                type="ibsheet"
                
                :ibsheet.sync="state.grid2"
                :ibsheet-options="state.gridOptions2"
                :ibsheet-data="state.list2"
                
              />
            </iui-content-box>
          </i-col>
         <i-col>
            <iui-tab-group>
              <iui-tab title="eContract Download">
                <iui-content-box>
                  <iui-container
                    type="ibsheet"
                    
                    :ibsheet.sync="state.grid1"
                    :ibsheet-options="state.gridOptions1"
                    :ibsheet-data="state.list1"
                  
                  />
                </iui-content-box>
              </iui-tab>
              
              <iui-tab title="T eCompletion Donwload">
                <iui-content-box>
                  <iui-container
                    type="ibsheet"
                    
                    :ibsheet.sync="state.grid2"
                    :ibsheet-options="state.gridOptions2"
                    :ibsheet-data="state.list2"
                    
                  />
                </iui-content-box>
              </iui-tab>
            </iui-tab-group>
          </i-col> 
        </i-row> -->
      </iui-container>

      
    </iui-tab-group>

  </otis-page>
</article>
</template>

<style scoped>
/* .iui-container,
.iui-container .row,
.iui-container .col {
  border: 1px solid gray;
  padding: 5px;
} */
#eContractSheetDiv,#TeCompletionSheetDiv {
  height : calc(100% - 1px) !important;
}

#contents {
    top: 0;
    left: 0;
    width: 100%;
}

#contents .scroll_wrap {
    overflow-y: hidden;
    height: calc(100vh - 20px) !important;
}
</style>
