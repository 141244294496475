
const state = () => ({
    searchInfo: {
      pgmCd: '',
      ctrtNo: '',
    },
    mainList: [],
    subList: [],

    cnfrmnMain: {
      cnfrmnNo: '',
      cnfrmnDivCd: '',
      cmpnsDivNm: '',
      droperDivNm: '',
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      cntrwkNo: '',
      cntrwkOdr: '',
      cnfrmnSttusCd: '',
      onlineYn: '',
      docmntSlngDe: '',
      fileNo: 0,
      rtrvlDt: '',
      sendngDt: '',
      tradeNo: '',
      tradeSfx: '',
      empId: '',
      tpmEmpId: '',
      isNotiFlag:'', /*20211215 엑셀91번 알럿을 보일지 말지 공백이면 그대로 진행*/
      mainNo : '',
    },
    partList: [],
    checkerList: [],
    pictureList: [],
    memberList: [],

    modalOnoffline: {
      cnfrmnNo: '',
      readonly : false,
      cnfrmnSttusCd : '', /*20211209 빠져있어서 추가*/ 
      onofflineModalId : '', /*20211209 필요함 백그라운드 리로드용 삭제버튼에 필요*/ 
    },

    modalAttach: {
      cnfrmnNo: '',
      photoSn: 0,
      fileNo: 0,
      readonly : false,
      attachFileModalId : '', /*20211209 필요함 백그라운드 리로드용 삭제버튼에 필요*/ 
    },

    tradeType : '',

    contractHistoryList: [], //20221202 ITSR62600

    modalCheckeSameList: [], //ITSR93020 20241223
  });
  const getters = {
    searchInfo: state => state.searchInfo,
    mainList: state => state.mainList,
    subList: state => state.subList,
    contractInfo: state => state.contractInfo,
    cnfrmnMain: state => state.cnfrmnMain,

    partList: state => state.partList,
    checkerList: state => state.checkerList,
    pictureList: state => state.pictureList,
    memberList: state => state.memberList,

    modalOnoffline : state => state.modalOnoffline,
    modalAttach : state => state.modalAttach,

    tradeType : state => state.tradeType,

    isChargeEmp : state => (
                            state.cnfrmnMain.empId == JSON.parse(sessionStorage.getItem('userInfo')).empNo 
                            || state.cnfrmnMain.tpmEmpId == JSON.parse(sessionStorage.getItem('userInfo')).empNo
                          ),
    contractHistoryList: state => state.contractHistoryList, //20221202 ITSR62600

    modalCheckeSameList: state => state.modalCheckeSameList, //ITSR93020 20241223
  };
  const mutations = {
    setSearchInfo(state, payload) {
      for (let key in state.searchInfo) {
        if (payload && payload.hasOwnProperty(key)) {
          state.searchInfo[key] = payload[key];
        }
      }
    },
    setMainList(state, payload) {
      state.mainList = payload;
    },
    setSubList(state, payload) {
      state.subList = payload;
    },
    setCnfrmnMain(state, payload) {
      for (let key in state.cnfrmnMain) {
        if (payload && payload.hasOwnProperty(key)) {
          state.cnfrmnMain[key] = payload[key];
        }
      }
    },
    setPartList(state, payload){
      state.partList = payload;
    },
    setCheckerList(state, payload){
      state.checkerList = payload;
    },
    setPictureList(state, payload){
      state.pictureList = payload;
    },
    setMemberList(state, payload){
      state.memberList = payload;
    },
    setModalOnoffline(state, payload) {
      for (let key in state.modalOnoffline) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalOnoffline[key] = payload[key];
        }
      }
    },
    setModalAttach(state, payload) {
      for (let key in state.modalAttach) {
        if (payload && payload.hasOwnProperty(key)) {
          state.modalAttach[key] = payload[key];
        }
      }
    },
    setHistoryList(state, payload){ //20221202 ITSR62600
      state.contractHistoryList = payload;
    },
    resetAllValues(state) {
      state.cnfrmnMain.cnfrmnNo	= '';
      state.cnfrmnMain.cnfrmnDivCd	= '';
      state.cnfrmnMain.cmpnsDivNm	= '';
      state.cnfrmnMain.droperDivNm	= '';
      state.cnfrmnMain.ctrtNo	= '';
      state.cnfrmnMain.ctrtOdr	= '';
      state.cnfrmnMain.ctrtDivCd	= '';
      state.cnfrmnMain.cntrwkNo	= '';
      state.cnfrmnMain.cntrwkOdr	= '';
      state.cnfrmnMain.cnfrmnSttusCd	= '';
      state.cnfrmnMain.onlineYn	= '';
      state.cnfrmnMain.docmntSlngDe	= '';
      state.cnfrmnMain.fileNo	= 0;
      state.cnfrmnMain.rtrvlDt	= '';
      state.cnfrmnMain.sendngDt	= '';
      state.cnfrmnMain.empId	= '';
      state.cnfrmnMain.tpmEmpId	= '';
      state.cnfrmnMain.isNotiFlag	= '';
      state.cnfrmnMain.mainNo = '';

      state.partList = [];
      state.checkerList = [];
      state.pictureList = [];
      state.memberList = [];

      state.modalOnoffline.cnfrmnNo	= '';
      state.modalOnoffline.readonly	= false;

      state.modalAttach.cnfrmnNo	= '';
      state.modalAttach.photoSn	= 0;
      state.modalAttach.fileNo	= 0;
      state.modalAttach.readonly	= false;
      state.modalAttach.attachFileModalId = ''; /*20211209 필요함 백그라운드 리로드용 삭제버튼에 필요*/ 
      state.contractHistoryList = []; //20221202 ITSR62600
      state.modalCheckeSameList = [];
    },
    setTradeType(state, payload) {
      state.tradeType  = payload;
    },
    setModalCheckeSameList(state, payload) {
      state.modalCheckeSameList = payload;
    },
  };
  const actions = {};
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  