<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <pmis-content-box :loading="allContentBoxLoading">
    <iui-container type="css-flex">
      <i-row height="330px" ref="srchList">
        <i-col>
          <SubList
            :otis-page="isOtisPage"
            :customer-page="isCustomerPage"
            @selRow="onClickSubListRow"
            @resetValues="onResetValues"
          />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <!-- <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" /> -->
          <!-- 20210914 lgksja 로딩바추가 -->
          <pmis-content-box :loading="contentBoxLoading">
            <i-row>
              <i-col>
                <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
              </i-col>
            </i-row>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import store from '@/view/confdoc/part/store/part.js';
// import MainList from './components/MainList.vue';
import SubList from './components/RSubList.vue';
export default {
  name: 'Part',
  components: {
    // MainList,
    SubList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'mainList', 'subList', 'cnfrmnMain', 'tradeType', 'isOtisPage', 'isCustomerPage']);
    $mapMutations(this, [
      'setSearchInfo',
      'setMainList',
      'setSubList',
      'setCnfrmnMain',
      'setPartList',
      'setCheckerList',
      'setPictureList',
      'setMemberList',
      'resetAllValues',
      'setTradeType',
      'setHistoryList', //20221202 ITSR62600
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        // {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '교체부품내역',
          compNm: 'Part_Parts',
          path: '@/view/confdoc/part/components/tab/Parts.vue',
        },
        {
          tabNm: '고객 확인자 정보',
          compNm: 'Part_Checker',
          path: '@/view/confdoc/part/components/tab/Checker.vue',
        },
        {
          tabNm: '작업증빙사진 첨부',
          compNm: 'Part_Picture',
          path: '@/view/confdoc/part/components/tab/Picture.vue',
        },
        {
          tabNm: '구성원정보',
          compNm: 'Part_Members',
          path: '@/view/confdoc/part/components/tab/Members.vue',
        },
        {
          tabNm: '진행 이력',
          compNm: 'OContractProgress_History',
          path: '@/view/contract/components/tab/HistoryList.vue', //20221202 ITSR62600
        },
      ],
      tabIndex: 0,
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      cnfrmnNo: '',
      contentBoxLoading: false,
      allContentBoxLoading : false,
      minMainNo : '', //ITSR93020용 선택된 row 현장명비교용
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'Part_SearchSubDetail', func: this.onSearchSubDetail});
    this.setCmmBtns(this.cmmBtns);

    this.addEvent({name: 'all_ContentBox_Loading', func: this.onAllContentBoxLoading});
  },
  mounted() {
    window.removeEventListener('resize', this.onResize);
    window.addEventListener('resize', this.onResize);
    var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
    //this.onSearch();
    //20220105 lgksja 써머리에서 넘어 올때만 바로 검색
    if (urls !== undefined && urls.params !== undefined) {
      var params = urls.params;
      console.log('params', params);
      if (params.summaryYm !== undefined && params.summaryYm !== '') {
        this.onSearch();
      }
    }
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'PartRSubList_PreSearch'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },

    // async onPrint() {
    //   this.$store.dispatch('mdi/doPrint');
    // },
    /*
    onClickMainListRow(row) {
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;

      // console.log('index row.tradeType', row.tradeType);

      this.setTradeType(row.tradeType);

      // console.log('before PartSubList_ReBindJqGrid');

      this.callEvent({name: 'PartRSubList_ReBindJqGrid', param: null});

      // console.log('after PartSubList_ReBindJqGrid');

      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        paymentType: '',
        statusCode: '',
        summaryYm: '',
        empNo: '',
      };

      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined && urls.params !== undefined) {
        var params= urls.params;
        console.log('params', params);

        param.paymentType = params.paymentType;
        param.statusCode  = params.statusCode;
        param.summaryYm   = params.summaryYm;
        param.empNo       = params.empNo;
      }

      // console.log('before PartSubList_PreSearch');

      this.callEvent({name: 'PartSubList_PreSearch', param: param});

      // console.log('after PartSubList_PreSearch');
    },
    */
    onClickSubListRow(row) {
      console.log('row', row);
      this.setTradeType(row.tradeType);
      this.cnfrmnNo = row.cnfrmnNo;
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;
      this.minMainNo = row.minMainNo; //ITSR93020용 선택된 row 현장명비교용
      if (this.cnfrmnNo !== undefined && this.cnfrmnNo != '') {
        this.onSearchSubDetail();
      }
    },
    async onSearchSubDetail() {
      this.contentBoxLoading = true;
      let param = {
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        cnfrmnNo: this.cnfrmnNo,
        isCustomerPage: this.isCustomerPage,
        tradeType: this.tradeType,
      };
      // eslint-disable-next-line
      let response = await axios.post('/confdoc/part/selectAllSubDetails', param);
      this.contentBoxLoading = false;
      
      response.data.cnfrmnMain['minMainNo'] = this.minMainNo;  //ITSR93020용 선택된 row 현장명비교용
      
      this.setCnfrmnMain(response.data.cnfrmnMain);
      this.setPartList(response.data.partList);
      this.setCheckerList(response.data.checkerList);
      this.setPictureList(response.data.pictureList);
      this.setMemberList(response.data.memberList);
      this.setHistoryList(response.data.historyList); //20221202 ITSR62600

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      const me = this;

      if (this.tabIndex == 0) {
        this.callEvent({name: 'Part_Parts_Load', param: {otisPage: me.isOtisPage}});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'Part_Checker_Load', param: {otisPage: me.isOtisPage}});
      } else if (this.tabIndex == 2) {
        this.callEvent({name: 'Part_Picture_Load', param: {otisPage: me.isOtisPage}});
      } else if (this.tabIndex == 3) {
        this.callEvent({name: 'Part_Members_Load', param: {otisPage: me.isOtisPage}});
      } else if (this.tabIndex == 4) {
        this.callEvent({name: 'OContractTabHistoryList_Load', param: undefined}); //20221202 ITSR62600
      }

      //$("table .ui-jqgrid-htable").eq(1).height("130%");
      //$("table .ui-jqgrid-btable").eq(1).height("130%");
      /*20210907 lgksja ~S*/
      //console.log("여기서 사이즈 조절 해보자",$(".ui-jqgrid-btable").eq(1).height());
      this.onResize();
      /*20210907 lgksja ~E*/
    },
    onResetValues() {
      console.log('onResetValues');
      this.callEvent({name: 'PartRSubList_ResetSubListData', param: null});
      this.resetAllValues();
      this.onCallEvent();
    },
    onResize() {
      /*
      $("table .ui-jqgrid-htable").eq(1).width("100%");
      $("table .ui-jqgrid-btable").eq(1).width("100%");
      var $grid = $("table .ui-jqgrid-btable").eq(1);
      var bottomObj = $grid;
      var pobj = $grid.closest(".ui-jqgrid").parent();
      var newWidth = pobj.width();
      //console.log("newWidth",newWidth);
      setTimeout(() => {
        //bottomObj.parent().parent().height($(".ui-jqgrid-btable").eq(1).height());
        $("#"+$grid.attr("id")).jqGrid("setGridWidth", newWidth, true);
        //bottomObj.parent().parent().height($(".ui-jqgrid-btable").eq(1).height());
        //console.log("aaanewWidth",newWidth);
        //console.log("bottomObj.parent().parent().height",bottomObj.parent().parent().height())
        $(".ui-jqgrid-bdiv").eq(1).height(bottomObj.parent().parent().height())
         setTimeout(() => {
            //console.log("aaaa",$(".ui-jqgrid-btable").eq(1).height());
            //console.log("bottomObj.parent().parent().height1",bottomObj.parent().parent().height())
            //bottomObj.parent().parent().height($(".ui-jqgrid-bdiv").eq(1).height());
            $(".ui-jqgrid-bdiv").eq(1).height($(".ui-jqgrid-btable").eq(1).height())
            //console.log("bottomObj.parent().parent().height2",bottomObj.parent().parent().height())
            //$("#"+$grid.attr("id")).jqGrid("setGridWidth", newWidth, true);
            //console.log("aaaa2",$(".ui-jqgrid-btable").eq(1).height());
            //console.log("bbb",newWidth);
            setTimeout(() => {
              //console.log("aaaa3",$(".ui-jqgrid-btable").eq(1).height());
              //console.log("bottomObj.parent().parent().height3",bottomObj.parent().parent().height())
              //bottomObj.parent().parent().height($(".ui-jqgrid-bdiv").eq(1).height());
              $(".ui-jqgrid-bdiv").eq(1).height($(".ui-jqgrid-btable").eq(1).height())
              //console.log("bottomObj.parent().parent().height4",bottomObj.parent().parent().height())
              //console.log("aaaa4",$(".ui-jqgrid-btable").eq(1).height());
            //   $("#"+$grid.attr("id")).jqGrid("setGridWidth", newWidth, true);
              //console.log("ccc");
            }, 1500);
        }, 1000);
        
        
      }, 100);
      */
    },
    onAllContentBoxLoading() {
      this.allContentBoxLoading = !this.allContentBoxLoading;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped></style>
