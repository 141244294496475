
/* 확인자 중복 체크 */
<template>
  <iui-container type="table" theme="none">
    <i-row>   
      <div style="font-size:14px;margin:3px;">
          -	현장은 다른데 동일 전화번호로 확인서를 보내시면 사후 모니터링 대상이 됩니다.
      </div> 
    </i-row>
    <i-row>     
      <i-col>
        <pmis-content-box :loading="listBoxLoading">         
          <iui-container type="jqgrid" :id="sheetId1" style="height:200px; width:99.9%;" />
        </pmis-content-box>
      </i-col>
    </i-row>   
   
  </iui-container>
  
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['modalCheckeSameList','cnfrmnMain']);
  },
  created() {
    this.addEvent({name: 'modalCheckeSameList_Load', func: this.onLoad});
    this.addEvent({name: 'modal_Checker_Save', func: this.checkerSave});
  },
  data() {
    return {
      sheetId1: 'modalSheet1' + this._uid,
      listBoxLoading: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'chngDate', label: '일시', width: 300, align: 'center'},
        {name: 'nm', label: '확인자명', width: 300, align: 'center'},
        {name: 'psitn', label: '현장명',  width: 700, align: 'left'},
        {name: 'telno', label: '확인자전화번호',  width: 400, align: 'center'},
      ],
    };

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      height: 300,
      width: 800,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      loadComplete: function() {

      },
    });
    

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      // var newHeight = pobj.height();
      // $grid.jqGrid("setGridHeight", newHeight, true);

      var bottomLeftObj = $grid
        .parents('.pmis-page')
        .find('.spacer')
        .eq(0)
        .next();
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 250, false);
      
    },
    onLoad() {
      console.log('OContractTabHistoryList_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      console.log("this.modalCheckeSameList:::::",this.modalCheckeSameList.sameItems);
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.modalCheckeSameList.sameItems})
        .trigger('reloadGrid');
      this.onResize();
    },
    async checkerSave(callback){
      console.log("this.modalCheckeSameList",this.modalCheckeSameList);
      console.log("cnfrmnMain",this.cnfrmnMain);
      this.callback = callback;
      if (await this.$confirm('확인자 정보를 저장하시겠습니까?')) {
        this.callEvent({name: 'all_ContentBox_Loading', param: {}}); //로딩시작
        let param = {
          ...this.modalCheckeSameList,
        };

        let url = (this.modalCheckeSameList.cmpnsDivNm == '20') ? '/confdoc/constcmpl/updateChecker':'/confdoc/part/updateChecker';

        var response = await axios
          .post(url, param)
          .catch(e => console.error('저장에 실패했습니다. ', e));
        if (response.status == 200) {
          this.$alert('저장을 완료했습니다.');
          let param = {
            ctrtNo: this.cnfrmnMain.ctrtNo,
            ctrtOdr: this.cnfrmnMain.ctrtOdr,
            ctrtDivCd: this.cnfrmnMain.ctrtDivCd,
          };
          if(this.modalCheckeSameList.cmpnsDivNm == '20'){
            this.callEvent({name: 'ConstCmplMainList_Search'});
          }else{
            this.callEvent({name: 'PartSubList_Search', param: param}); // 부품교체확인서(계약)의 SubList의 onSearch 호출
            this.callEvent({name: 'PartRSubList_Search', param: param}); // 부품교체확인서(청구)의 RSubList의 onSearch 호출
          }
          

          
        } else {
          me.$alert('저장에 실패하였습니다.');
        }

        this.callEvent({name: 'all_ContentBox_Loading', param: {}}); //로딩시작
      }
      callback();
    }
  },
  computed: {
    
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>

