<template>
  <pmis-page :search-company-visible="false" :search-project-visible="false">
    <pmis-content-box :loading="allContentBoxLoading">
    <iui-container type="css-flex">
      <i-row height="330px" ref="srchList">
        <i-col>
          <MainList otis-page @click="onClickMainListRow" @resetValues="onResetValues" />
        </i-col>
      </i-row>
      <i-spacer />
      <i-row>
        <i-col>
          <!-- <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" /> -->
          <!-- 20210914 lgksja 로딩바추가 -->
          <pmis-content-box :loading="contentBoxLoading">
            <i-row>
              <i-col>
                <iui-tab-old :comps="tabComps" :isAuto="false" @click="setTabIndex" />
              </i-col>
            </i-row>
          </pmis-content-box>
        </i-col>
      </i-row>
    </iui-container>
    </pmis-content-box>
  </pmis-page>
</template>

<script>
import store from '@/view/confdoc/constcmpl/store/constcmpl.js';
import MainList from './components/MainList.vue';
export default {
  name: 'ConstCmpl',
  components: {
    MainList,
  },
  beforeCreate() {
    $init(this, store);
    $mapGetters(this, ['searchInfo', 'cnfrmnMain', 'tradeType']);
    $mapMutations(this, [
      'setSearchInfo',
      'setMainList',
      'setSubList',
      'setCnfrmnMain',
      'setPartList',
      'setCheckerList',
      'setPictureList',
      'setMemberList',
      'resetAllValues',
      'setTradeType',
      'setHistoryList', //20221202 ITSR62600
    ]);
  },
  data() {
    return {
      cmmBtns: [
        {name: '조회', class: 's', callback: this.onSearch},
        // {name: '신규', class: 'b', callback: this.onAdd},
        // {name: '저장', class: 'b', callback: this.onSave},
        // {name: '삭제', class: 'b', callback: this.onDelete},
        // {name: '출력', class: 'p', callback: this.onPrint},
      ],
      tabComps: [
        {
          tabNm: '교체부품내역',
          compNm: 'ConstCmpl_Parts',
          path: '@/view/confdoc/constcmpl/components/tab/Parts.vue',
        },
        {
          tabNm: '고객 확인자 정보',
          compNm: 'ConstCmpl_Checker',
          path: '@/view/confdoc/constcmpl/components/tab/Checker.vue',
        },
        {
          tabNm: '작업증빙사진 첨부',
          compNm: 'ConstCmpl_Picture',
          path: '@/view/confdoc/constcmpl/components/tab/Picture.vue',
        },
        {
          tabNm: '구성원정보',
          compNm: 'ConstCmpl_Members',
          path: '@/view/confdoc/constcmpl/components/tab/Members.vue',
        },
        {
          tabNm: '진행 이력',
          compNm: 'ConstCmpl_OContract_History',
          path: '@/view/contract/components/tab/HistoryList.vue', //20221202 ITSR62600
        },
      ],
      tabIndex: 0,
      constructNo: '',
      constructSeq: '',
      ctrtNo: '',
      ctrtOdr: '',
      ctrtDivCd: '',
      cnfrmnNo: '',
      contentBoxLoading: false,
      allContentBoxLoading : false,
      mainNo : '', //ITSR93020용 선택된 row 현장명비교용
    };
  },
  computed: {},
  created() {
    this.addEvent({name: 'ConstCmpl_SearchSubDetail', func: this.onSearchDetail});
    this.setCmmBtns(this.cmmBtns);

    this.addEvent({name: 'all_ContentBox_Loading', func: this.onAllContentBoxLoading});
  },
  mounted() {
    var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
    //this.onSearch();
    //20220105 lgksja 써머리에서 넘어 올때만 바로 검색
    if (urls !== undefined && urls.params !== undefined) {
      var params = urls.params;
      if (params.summaryYm !== undefined && params.summaryYm !== '') {
        this.onSearch();
      }
    }
  },

  methods: {
    async onSearch() {
      this.callEvent({name: 'ConstCmplMainList_PreSearch'});
    },
    // async onAdd() {

    // },
    // async onSave() {

    // },
    // async onDelete() {

    // },

    // async onPrint() {
    //   this.$store.dispatch('mdi/doPrint');
    // },

    onClickMainListRow(row) {
      this.cnfrmnNo = row.cnfrmnNo;
      this.constructNo = row.constructNo;
      this.constructSeq = row.constructSeq;
      this.ctrtNo = row.ctrtNo;
      this.ctrtOdr = row.ctrtOdr;
      this.ctrtDivCd = row.ctrtDivCd;
      this.mainNo = row.mainNo; //ITSR93020용 선택된 row 현장명비교용

      console.log('index row.tradeType', row.tradeType);

      this.setTradeType(row.tradeType);

      if (this.cnfrmnNo !== undefined && this.cnfrmnNo != '') {
        this.onSearchDetail();
      }
    },
    async onSearchDetail() {
      this.contentBoxLoading = true;
      let param = {
        constructNo: this.constructNo,
        constructSeq: this.constructSeq,
        ctrtNo: this.ctrtNo,
        ctrtOdr: this.ctrtOdr,
        ctrtDivCd: this.ctrtDivCd,
        cnfrmnNo: this.cnfrmnNo,
        isCustomerPage: false,
        tradeType: this.tradeType,
      };
      // eslint-disable-next-line
      let response = await axios.post('/confdoc/constcmpl/selectAllDetails', param);
      this.contentBoxLoading = false;

      response.data.cnfrmnMain['mainNo'] = this.mainNo;  //ITSR93020용 선택된 row 현장명비교용

      this.setCnfrmnMain(response.data.cnfrmnMain);
      this.setPartList(response.data.partList);
      this.setCheckerList(response.data.checkerList);
      this.setPictureList(response.data.pictureList);
      this.setMemberList(response.data.memberList);
      this.setHistoryList(response.data.historyList); //20221202 ITSR62600

      this.onCallEvent();
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.callEvent({name: 'setActiveTab', param: this.tabIndex});
      this.onCallEvent();
    },
    onCallEvent() {
      if (this.tabIndex == 0) {
        this.callEvent({name: 'ConstCmpl_Parts_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 1) {
        this.callEvent({name: 'ConstCmpl_Checker_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 2) {
        this.callEvent({name: 'ConstCmpl_Picture_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 3) {
        this.callEvent({name: 'ConstCmpl_Members_Load', param: {otisPage: true}});
      } else if (this.tabIndex == 4) {
        this.callEvent({name: 'OContractTabHistoryList_Load', param: undefined}); //20221202 ITSR62600
      }
    },
    onResetValues() {
      console.log('onResetValues');
      this.resetAllValues();
      this.onCallEvent();
    },
    onAllContentBoxLoading() {
      this.allContentBoxLoading = !this.allContentBoxLoading;
    }
  },
};
</script>

<style scoped></style>
