<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="130px">
      <i-col>
        <pmis-content-box no-border-box>
          <template #title-right>
            <iui-button :enable="isCanSave" v-show="isCanSave" @click="saveChecker">저장</iui-button>
          </template>
          <iui-container type="jqgrid" :id="sheetId1" :loading="listBoxLoading" style="width:99.9%" />
        </pmis-content-box>
      </i-col>
    </i-row>
    <i-row>
      <i-col>
        <div style="font-size:12px;margin-top:3px;">
          * <b>URL복사</b> : 서명요청시 발송된 카톡의 URL을 복사할 수 있습니다.<br />
          <p style="padding-left: 5.5em">복사된 URL을 브라우저에 붙여넣기 하시면 완료확인서를 조회 할 수 있습니다.</p>
          * 전화번호: 휴대폰이 아니면 메시지가 전송되지 않습니다.<br />
          * Email입력을 하지 않을 경우 메일 알림 전송은 불가합니다.
        </div>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['cnfrmnMain', 'checkerList', 'tradeType', 'isChargeEmp']);
  },
  created() {
    this.addEvent({name: 'ConstCmpl_Checker_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId1: 'sheet1' + this._uid,
      listBoxLoading: false,
      otisPage: false,
    };
  },
  mounted() {
    const OPT = {
      Cols: [
        {name: 'cnfrmrSn', label: 'cnfrmrSn', width: 0, hidden: true},
        {name: 'cnfrmnNo', label: 'cnfrmnNo', width: 0, hidden: true},
        {name: 'shrtenUrl', label: 'shrtenUrl', width: 0, hidden: true},
        {name: 'resend', label: 'resend', width: 0, hidden: true},
      ],
    };

    if ($isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY') {
      OPT.Cols.push(
        // eslint-disable-next-line
        {
          name: 'urlcopy',
          label: 'URL 복사',
          width: 100,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            return cellvalue == 'true' ? '<button>URL 복사</button>' : '';
          },
        }
      );
      OPT.Cols.push(
        // eslint-disable-next-line
        {
          name: 'resendNm',
          label: '메시지 재전송',
          width: 120,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            return cellvalue == 'true' ? '<button>메시지 재전송</button>' : '';
          },
        }
      );
    }

    OPT.Cols.push({
      name: 'psitn',
      label: "소속<font color='red'>(필수)</font>",
      width: 100,
      align: 'left',
      edittype: 'text',
      editable: true,
      Required: 1,
    });
    OPT.Cols.push({
      name: 'clsf',
      label: "직급<font color='red'>(필수)</font>",
      width: 100,
      align: 'left',
      edittype: 'text',
      editable: true,
      Required: 1,
    });
    OPT.Cols.push({
      name: 'nm',
      label: "확인자<font color='red'>(필수)</font>",
      width: 100,
      align: 'left',
      edittype: 'text',
      editable: true,
      Required: 1,
    });
    OPT.Cols.push({
      name: 'telno',
      label: "전화번호<font color='red'>(필수)</font>",
      width: 100,
      align: 'left',
      edittype: 'text',
      editable: true,
      Required: 1,
    });
    OPT.Cols.push({
      name: 'email',
      label: 'Email(선택)',
      width: 100,
      align: 'left',
      edittype: 'text',
      editable: true,
      Required: 1,
    });

    const me = this;
    $('#' + me.sheetId1).jqGrid({
      // height:50,
      autowidth: true,
      shrinkToFit: true,
      selectionMode: 'singlerow',
      colModel: OPT.Cols,
      onSelectRow: function(rowid) {
        var $grid = $('#' + me.sheetId1);
        if (me.cnfrmnMain.cnfrmnSttusCd == '10' || me.cnfrmnMain.cnfrmnSttusCd == '30') {
          $grid.editRow(rowid, true);
        }
      },
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId1).jqGrid('getRowData', rowId);

        if (iCol == $('#' + me.sheetId1).jqGrid('getGridParam').iColByName['urlcopy']) {
          me.onUrlCopy(rowData);
        } else if (iCol == $('#' + me.sheetId1).jqGrid('getGridParam').iColByName['resendNm']) {
          me.onResend(rowData);
        }
      },
    });

    window.addEventListener('resize', this.onResize);
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId1);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
    },
    onLoad(param) {
      console.log('ConstCmpl_Checker_Load');
      if (param !== undefined) {
        this.otisPage = param.otisPage;
      }
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      this.onResize();
      $('#' + this.sheetId1)
        .clearGridData(true)
        .setGridParam({data: this.checkerList})
        .trigger('reloadGrid');
    },
    async saveChecker() {
      if (!(this.cnfrmnMain.cnfrmnSttusCd == '10' || this.cnfrmnMain.cnfrmnSttusCd == '30')) {
        alert('작성중 또는 회수 상태에서만 고객 확인자 정보를 저장할 수 있습니다.');
        return;
      }

      var $grid = $('#' + this.sheetId1);
      var ids = $grid.getDataIDs();
      var rowData = $grid.getRowData(ids[0]);

      if (rowData.psitn.length == 0) {
        this.$alert('소속 정보를 반드시 입력해 주세요.');
        return;
      }

      if (rowData.clsf.length == 0) {
        this.$alert('직급 정보를 반드시 입력해 주세요.');
        return;
      }

      if (rowData.nm.length == 0) {
        this.$alert('확인자 정보를 반드시 입력해 주세요.');
        return;
      }

      if (rowData.telno.length == 0) {
        this.$alert('전화번호 정보를 반드시 입력해 주세요.');
        return;
      }

      let telNoPattern = /^(01[0|1|6|7|8|9])[0-9]{3,4}[0-9]{4}/; // 휴대폰번호 정규식 (01x 번호만 검색)
      var chkTelNo = rowData.telno
        .replace(/ /gi, '')
        .replace(/-/gi, '')
        .replace(/\(/gi, '')
        .replace(/\)/gi, '');
      console.log('telNoPattern', telNoPattern.test(chkTelNo));
      console.log('length', chkTelNo.length);
      if (!(telNoPattern.test(chkTelNo) && (chkTelNo.length == 10 || chkTelNo.length == 11))) {
        this.$alert('전화번호 정보는 휴대전화번호를 입력해 주세요.');
        return;
      }

      if (rowData.email.length > 0) {
        let checkEmail = rowData.email.replace(/ /gi, '');
        let emailPattern = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // 이메일 패턴
        console.log('emailPattern', emailPattern.test(checkEmail));
        if (!emailPattern.test(checkEmail)) {
          this.$alert('이메일 정보를 올바로 입력해 주세요.');
          return;
        }
        //20210924 lgksja 이메일 주소에 test 또는 otis.co.kr 이 있으면 안됨
        let exp = /test|otis.co.kr|otis.com/;
        checkEmail = checkEmail.toLowerCase();
        if (exp.test(checkEmail)) {
          this.$alert('고객확인자정보에 임직원 이메일 주소(도메인)를 입력하셨습니다.');
          return;
        }
      }

      var me = this;
       /**
       *ITSR93020
       *[완료 확인서] 고객 확인자의 동일 전화번호 Check (OEK, OSE 모두 적용)
       *20241223
      */
      let param2 = {
          ...rowData,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
          sameItems : [],
          cmpnsDivNm : "20", //공사완료확인서 
          //cnfrmnMain : this.cnfrmnMain,
      };
      console.log("param2_____",param2);
      this.callEvent({name: 'all_ContentBox_Loading', param: {}}); //로딩시작

      var response2 = await axios
          .post('/confdoc/part/selectSameChecker', param2)
          .catch(function(error) {
            //me.callEvent({name: 'ConstCmpl_all_ContentBox_Loading', param: {}}); //로딩끝
            console.log('========error====================');
            console.log(error);
          });
          //.catch(e => console.error('조회회 실패했습니다. ', e));
      this.callEvent({name: 'all_ContentBox_Loading', param: {}}); //로딩끝
      console.log("this.cnfrmnMain.mainName",this.cnfrmnMain.cnfrmnNo);
      var items = response2.data.filter(
       // data => !(data.psitn == me.cnfrmnMain.mainName && data.nm == param2.nm)
       data => !(data.cnfrmnNo == me.cnfrmnMain.cnfrmnNo || (data.mainNo == me.cnfrmnMain.mainNo && data.nm == param2.nm))
      );
      console.log("items",items);
      if(items.length > 0){
        param2.sameItems = items;
        console.log("이벤트 콜 ConstCmplCheckeSameListManage_Popup");
        this.callEvent({name: 'ConstCmplCheckeSameListManage_Popup', param: param2}); //
       
      }else{
        if (await this.$confirm('확인자 정보를 저장하시겠습니까?')) {
            let param = {
            ...rowData,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
            };

            var response = await axios
            .post('/confdoc/constcmpl/updateChecker', param)
            .catch(e => console.error('저장에 실패했습니다. ', e));
            if (response.status == 200) {
            this.$alert('저장을 완료했습니다.');
            this.callEvent({name: 'ConstCmplMainList_Search'});
            } else {
            me.$alert('저장에 실패하였습니다.');
            }
        }
      }
    },
    async onUrlCopy(rowData) {
      if (rowData.shrtenUrl != '') {
        var me = this;
        this.$copyText(rowData.shrtenUrl).then(
          function() {
            me.$alert(
              '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
            );
          },
          function(e) {
            me.$alert('클립보드 복사에 실패하였습니다.');
            console.log(e);
          }
        );
      }
    },
    async onResend(rowData) {
      if (rowData.resend == 'true') {
        if (!($isAdmin() || this.isChargeEmp)) {
          this.$alert('SCD 관리자 또는 업무담당자만 해당 업무를 수행할 수 있습니다.');
          return;
        }

        if (!(await this.$confirm('서명요청 메시지를 재전송 하시겠습니까?'))) return;

        let param = {
          ...rowData,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        var response = await axios
          .post('/confdoc/constcmpl/resend', param)
          .catch(e => console.error('서명요청 메시지 재전송에 실패했습니다. ', e));
        if (response.status == 200) {
          this.$alert('서명요청 메시지 재전송을 완료했습니다.');
          this.callEvent({name: 'ConstCmpl_SearchSubDetail'});
        } else {
          this.$alert('서명요청 메시지 재전송에 실패했습니다.');
        }
      }
    },
  },
  computed: {
    isCanSave() {
      return $isAdmin() ||
        this.isChargeEmp ||
        ((this.tradeType == 'OTIS' || this.tradeType == 'CCPY') &&
          (this.cnfrmnMain.cnfrmnSttusCd == '10' || this.cnfrmnMain.cnfrmnSttusCd == '30'))
        ? true
        : false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
